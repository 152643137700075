import { Measurement } from './types'

export const stroke = 'rgb(7, 160, 232)'
export const primaryColor = 'rgb(255, 136, 0)'
export const primaryColorTransparent = 'rgba(255, 136, 0, .05)'
export const MARGIN_VALUE = 40

export interface ChartData {
  [x: string]: number | Date
}

// this unit is in pounds.
export const unitConversion = 2.20462

export const AXIS = {
  X: 'a',
  Y: 'b',
}

export const getWeight = (weight: number, preferredUnits: string) =>
  preferredUnits === 'lbs' ? Math.round(weight * unitConversion) : weight

export enum SORT {
  ASC,
  DESC,
}

export const sortMeasurements = (
  measurements: Measurement[],
  direction: SORT = SORT.ASC
) =>
  measurements.sort((a, b) =>
    direction === SORT.ASC
      ? //@ts-expect-error Date conversion will not be broken
        new Date(a.created_at) - new Date(b.created_at)
      : //@ts-expect-error Date conversion will not be broken
        new Date(b.created_at) - new Date(a.created_at)
  )
