import { useFetchCoursesQuery } from 'graphql/types'
import * as React from 'react'
import { useState } from 'react'

import { IScreenProp } from '../../types'
import ButtonGroup from '../ButtonGroup'
import P from '../Primatives'
import { LessonList } from './LessonList'
import { LibraryWrapper } from './LibraryWrapper'

export const Library = ({ ...props }: Partial<IScreenProp>) => {
  const [libraryContent, setLibraryContent] = useState('lessons')
  const { data, loading } = useFetchCoursesQuery()
  if (loading) {
    return null
  }
  const {
    library: { courses, resources },
  } = data
  const buttonGroupOptions = [
    {
      id: 'lessons',
      label: 'Lessons',
      onClick: () => {
        setLibraryContent('lessons')
      },
    },
    {
      id: 'resources',
      label: 'Resources',
      onClick: () => {
        setLibraryContent('resources')
      },
    },
  ]

  return (
    <LibraryWrapper {...props}>
      <P.div>
        {resources.length > 0 && (
          <P.div className="text-center mb-10">
            <ButtonGroup
              options={buttonGroupOptions}
              selectedId={libraryContent}
            />
          </P.div>
        )}
        <P.div className="flex flex-col space-y-5">
          {courses.length > 0 && libraryContent === 'lessons' && (
            <LessonList content={courses as any} />
          )}
          {resources.length > 0 && libraryContent === 'resources' && (
            <LessonList content={resources as any} />
          )}
          {resources.length === 0 && courses.length === 0 && (
            <P.div>No course at this moment.</P.div>
          )}
        </P.div>
      </P.div>
    </LibraryWrapper>
  )
}
