import * as React from 'react'

import { UnreadIndicator } from '../UnreadIndicator'
import { IChannelInfo } from './MessageButton'
import { Avatar } from 'components/Chat/Components/Avatar'
import { getNameFromMessage } from 'components/Chat/helpers'

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

export const NewMessage = ({
  lastMessage,
  unreadCount,
  type,
  user,
}: IChannelInfo) => {
  return (
    <div
      className="flex flex-col p-3 md:p-4 mb-2 z-0 rounded-lg bg-white active:bg-gray-100 shadow-md hover:shadow-lg hover:bg-gray-100 transition-all relative cursor-pointer"
      onClick={() => (window.location.href = `/chat/${type}`)}
    >
      <div className="flex flex-row gap-2 items-center">
        <Avatar image={user.image} user={user} />
        <div className="flex-grow overflow-hidden" style={{ lineHeight: '18px' }}>
          <small className="block">
            <span className="font-semibold text-gray-500">{getNameFromMessage({ user })}</span> (
            {type === 'messaging' ? 'Health Coach' : 'Care Team'})
          </small>
          <div className="text-gray-800 truncate">
            {truncateString(lastMessage, 200)}
          </div>
        </div>
      </div>
      <UnreadIndicator unReadNotification={unreadCount} />
    </div>
  )
}
