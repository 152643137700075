import * as Sentry from '@sentry/react'
import * as React from 'react'

import { IScreenProp } from '../../types'
import Provider from '../../util/hoc/Provider'
import { CourseScreen } from './CourseScreen'

const CoursePage = (props: Partial<IScreenProp>) => {
  return <CourseScreen {...props} />
}

export default Provider(
  Sentry.withErrorBoundary(CoursePage, {
    fallback: <p>An error has occurred</p>,
  })
)
