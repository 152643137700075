import React from 'react'
import { MessageInput as StreamChatMessageInput } from 'stream-chat-react'

export default function MessageInput() {
  return (
    <div className="message-input-container">
      <StreamChatMessageInput grow={true} />
    </div>
  )
}
