window.panelSetContent = (title, content) => {
  $('#panel-title').html(title)
  $('#panel-content').html(content)
}

window.showPanel = (title, content) => {
  $('#panel').removeClass('hidden')

  setTimeout(() => {
    $('#panel').addClass('active')
  }, 50)
}

window.hidePanel = () => {
  $('#panel').removeClass('active')

  setTimeout(() => {
    $('#panel').addClass('hidden')
  }, 300)
}

window.panelStartLoading = () => {
  $('#panel-content').hide()
  $('#panel-loading').fadeIn()
  window.showPanel()
}

window.panelStopLoading = () => {
  $('#panel-loading').fadeOut()
  $('#panel-content').fadeIn()
}
