import Cookies from 'js-cookie'
import * as React from 'react'
import ReactPlayer from 'react-player'
import {
  messageHasReactions,
  MessageOptions,
  MessageRepliesCountButton,
  MessageStatus,
  ReactionSelector,
  SimpleReactionsList,
  useMessageContext,
} from 'stream-chat-react'

import { COOKIE_WALKTHROUGH } from '../../Walkthrough'

interface Attachment {
  preview?: string
  source?: string
  type: string
}

export default function SysVideoMessage() {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
  } = useMessageContext()

  const messageWrapperRef = React.useRef(null)
  const playerRef = React.useRef(null)
  const playIconVideo = React.useRef(null)
  const { source, preview } =
  (!!message.attachment && (message.attachment as Attachment)) || {}

  const hasReactions = messageHasReactions(message)

  const playLongVideo = () => {
    if (!!playIconVideo.current) {
      playIconVideo.current.remove()
      playIconVideo.current = null
      const videoElem = playerRef.current.getInternalPlayer()
      videoElem.src = source
      videoElem.muted = false
      videoElem.requestFullscreen({ navigationUI: 'show' })

      Cookies.set(COOKIE_WALKTHROUGH, 'true')
    }
  }

  const closeVideo = (e) => {
    e.stopPropagation()
    e.preventDefault()
    document
      .querySelector('.welcome-video-message')
      .classList.remove('hightlight')
  }

  return (
    <div className="sys-message-wrapper ease-in">
      <div className="sys-message-wrapper-content">
        <MessageOptions messageWrapperRef={messageWrapperRef} />
        {!!source && (
          <div
            className="relative welcome-video-message"
            onClick={playLongVideo}
          >
            <CloseIcon
              fill="white"
              className="closeButton"
              onClick={closeVideo}
            />
            <div
              ref={playIconVideo}
              className="absolute play-icon-video z-10 w-full h-full top-0 left-0"
            >
              <PlayIcon
                fill="white"
                className="relative w-20 h-20 inset-y-1/2 m-auto"
              />
            </div>
            <ReactPlayer
              controls
              height={640}
              loop
              muted
              playing
              ref={playerRef}
              url={preview || source}
              width={360}
            />
          </div>
        )}
        {showDetailedReactions && isReactionEnabled && (
          <ReactionSelector ref={reactionSelectorRef} />
        )}
        {!!message.html && (
          <div className="str-chat__message-text">
            <div className="str-chat__message-text-inner str-chat__message-simple-text-inner">
              <div dangerouslySetInnerHTML={{ __html: message.html }} />
            </div>
          </div>
        )}
        <MessageStatus />
        {hasReactions && !showDetailedReactions && isReactionEnabled && (
          <SimpleReactionsList />
        )}
        <MessageRepliesCountButton reply_count={message.reply_count} />
      </div>
    </div>
  )
}

const PlayIcon = ({ fill, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill={fill}
      className={className}
    >
      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM7 6l8 4-8 4V6z" />
    </svg>
  )
}

const CloseIcon = ({ fill, className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={fill}
    className={className}
    onClick={onClick}
  >
    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
  </svg>
)
