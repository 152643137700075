import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import CoursePage from '../../components/Library/CoursePage'

export default class extends Controller {
  static targets = ['lesson']
  static values = {
    data: {
      imageTagHtml: String,
      name: String,
      contentTagHtml: String,
      previous: String | undefined,
      next: String | undefined,
    },
  }

  renderComponent() {
    ReactDOM.render(
      <CoursePage lessonData={this.dataValue} />,
      this.lessonTarget
    )
  }

  connect() {
    this.renderComponent()
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.lessonTarget)
  }
}
