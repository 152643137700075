import * as React from 'react'

interface Props {
  id?: string
  role?: string
  className?: string
  children?: React.ReactNode
  onClick?: (...x: any) => void
}

export default {
  h1: withPropsSegregated(
    (props): JSX.Element => <h1 {...props.rest}>{props.children}</h1>
  ),
  p: withPropsSegregated(
    (props): JSX.Element => <p {...props.rest}>{props.children}</p>
  ),
  ol: withPropsSegregated(
    (props): JSX.Element => <ol {...props.rest}>{props.children}</ol>
  ),
  li: withPropsSegregated(
    (props): JSX.Element => <li {...props.rest}>{props.children}</li>
  ),
  a: withPropsSegregated(
    (props): JSX.Element => <a {...props.rest}>{props.children}</a>
  ),
  div: withPropsSegregated(
    (props): JSX.Element => <div {...props.rest}>{props.children}</div>
  ),
  button: withPropsSegregated(
    (props): JSX.Element => <button {...props.rest}>{props.children}</button>
  ),
  svg: withPropsSegregated(
    (props): JSX.Element => <svg {...props.rest}>{props.children}</svg>
  ),
  path: withPropsSegregated(
    (props): JSX.Element => <path {...props.rest}>{props.children}</path>
  ),
  img: withPropsSegregated(
    (props): JSX.Element => <img {...props.rest}>{props.children}</img>
  ),
}

function withPropsSegregated(Component) {
  // eslint-disable-next-line react/display-name
  return (props: Props) => {
    const { children, ...rest } = props
    return <Component rest={rest}>{children}</Component>
  }
}
