import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export enum ActivityLevel {
  /** Athlete - rigorous exercise 1-2 times per day */
  Athlete = 'athlete',
  /** High - excercise 4-5 times per week */
  High = 'high',
  /** Low - low activity */
  Low = 'low',
  /** Medium - exercise 1-3 times per week */
  Medium = 'medium',
  /** Very High - exercise 6+ times per week */
  VeryHigh = 'very_high'
}

export type Carbs = {
  __typename?: 'Carbs';
  gm: Scalars['Float'];
  ratio: Scalars['Float'];
};

export type CareTeam = {
  __typename?: 'CareTeam';
  businessHours?: Maybe<Scalars['String']>;
  coachChannelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  medicalChannelId?: Maybe<Scalars['String']>;
  withinBusinessHours?: Maybe<Scalars['Boolean']>;
};


export type CareTeamWithinBusinessHoursArgs = {
  time: Scalars['ISO8601DateTime'];
};

export type CareTeamMember = {
  __typename?: 'CareTeamMember';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  memberType: CareTeamMemberMember;
};

export enum CareTeamMemberMember {
  CareCoordinator = 'care_coordinator',
  Coach = 'coach',
  Provider = 'provider'
}

export type Chat = {
  __typename?: 'Chat';
  accessToken: Scalars['String'];
  appId: Scalars['String'];
  channelUrl: Scalars['String'];
  userId: Scalars['String'];
};

export type ContentFeed = {
  __typename?: 'ContentFeed';
  description: Scalars['String'];
  id: Scalars['ID'];
  items: Array<ContentFeedItem>;
  title: Scalars['String'];
};

export type ContentFeedItem = {
  __typename?: 'ContentFeedItem';
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type Course = {
  __typename?: 'Course';
  coverImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lessons: Array<Lesson>;
  name: Scalars['String'];
  slug: Scalars['String'];
  status: Scalars['String'];
};

/** Autogenerated input type of CreateMealPlan */
export type CreateMealPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dietType: Diet;
  durationDays?: Maybe<Scalars['Int']>;
  durationWeeks?: Maybe<Scalars['Int']>;
  restrictions: Scalars['JSON'];
  start?: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of CreateMealPlan. */
export type CreateMealPlanPayload = {
  __typename?: 'CreateMealPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mealPlan: MealPlan;
};

export type Day = {
  __typename?: 'Day';
  id: Scalars['ID'];
  meals: Array<Meal>;
};

export enum Diet {
  Keto = 'keto',
  LowCarb = 'low_carb'
}

export type Error = {
  __typename?: 'Error';
  key?: Maybe<MessageKey>;
  optional?: Maybe<Scalars['String']>;
};

export type Fats = {
  __typename?: 'Fats';
  gm: Scalars['Float'];
  ratio: Scalars['Float'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum HealthGoal {
  /** Build Muscle */
  BuildMuscle = 'build_muscle',
  /** Maintain Current Level */
  HealthyHabits = 'healthy_habits',
  /** Lose 20-50 lbs */
  Lose20to50 = 'lose20to50',
  /** Lose 50-100 lbs */
  Lose50to100 = 'lose50to100',
  /** Lose 100+ lbs */
  Lose100plus = 'lose100plus',
  /** Lose Weight */
  LoseWeight = 'lose_weight',
  /** Lower My> A1C */
  LowerA1c = 'lower_a1c'
}

export type HealthProfile = {
  __typename?: 'HealthProfile';
  activityLevel?: Maybe<ActivityLevel>;
  cm?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentWeight?: Maybe<Weight>;
  feet?: Maybe<Scalars['Int']>;
  healthGoal?: Maybe<HealthGoal>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inches?: Maybe<Scalars['Int']>;
  measurements: Array<Measurement>;
  notes?: Maybe<Scalars['String']>;
  processed?: Maybe<Scalars['Boolean']>;
  targetWeight?: Maybe<Weight>;
  units?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type Homescreen = {
  __typename?: 'Homescreen';
  contentFeeds?: Maybe<Array<ContentFeed>>;
  quickActions: Array<QuickAction>;
};

export enum ImageVariant {
  /**
   * convert: jpg
   * resize_to_fill: [200, 130, {:gravity=>"Center"}]
   */
  Medium = 'medium',
  /**
   * convert: jpg
   * resize_to_fill: [128, 128]
   */
  Small = 'small',
  /**
   * convert: jpg
   * resize_to_fill: [64, 64]
   */
  Thumb = 'thumb'
}

export type Ingredient = {
  __typename?: 'Ingredient';
  essential?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modifiers?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['String']>;
};

export type Lesson = {
  __typename?: 'Lesson';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  status: Scalars['Boolean'];
};

export type Library = {
  __typename?: 'Library';
  courses: Array<Course>;
  resources: Array<Course>;
};

export type Macros = {
  __typename?: 'Macros';
  calories: Scalars['Float'];
  carbs: Carbs;
  diet: Diet;
  fats: Fats;
  protein: Protein;
};

/** Chat config or error */
export type MaybeChat = Chat | Error;

/** Chat config or error */
export type MaybeStreamChat = Error | StreamChat;

export type Meal = {
  __typename?: 'Meal';
  alternativeRecipes: Array<Recipe>;
  eaten: Scalars['Boolean'];
  id: Scalars['ID'];
  mealType: MealName;
  recipe?: Maybe<Recipe>;
  skipped: Scalars['Boolean'];
};

export enum MealName {
  Breakfast = 'breakfast',
  Dinner = 'dinner',
  Lunch = 'lunch',
  Snack = 'snack'
}

export type MealPlan = {
  __typename?: 'MealPlan';
  createdAt: Scalars['ISO8601DateTime'];
  day?: Maybe<Day>;
  dietType: Diet;
  durationDays?: Maybe<Scalars['Int']>;
  durationWeeks?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  macros?: Maybe<Scalars['JSON']>;
  meals?: Maybe<Scalars['JSON']>;
  restrictions?: Maybe<Scalars['JSON']>;
  scaleGroceries?: Maybe<Scalars['Int']>;
  skipMeals?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['ISO8601Date']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  weeks: Array<Week>;
};


export type MealPlanDayArgs = {
  date: Scalars['ISO8601Date'];
};

export type Measurement = {
  __typename?: 'Measurement';
  id: Scalars['ID'];
  measuredAt: Scalars['ISO8601DateTime'];
  notes?: Maybe<Scalars['String']>;
  weight: Weight;
};

export enum MessageKey {
  ChatSetupTriggered = 'CHAT_SETUP_TRIGGERED',
  FeatureDisabled = 'FEATURE_DISABLED',
  Other = 'OTHER',
  ProviderError = 'PROVIDER_ERROR'
}

export type Mutation = {
  __typename?: 'Mutation';
  createMealPlan?: Maybe<CreateMealPlanPayload>;
  trackHealthProgress?: Maybe<TrackHealthProgressPayload>;
  updateHealthProfile?: Maybe<UpdateHealthProfilePayload>;
  updateHealthProgress?: Maybe<UpdateHealthProgressPayload>;
  updateMeal?: Maybe<UpdateMealPayload>;
  updateOnboardingStatus?: Maybe<UpdateOnboardingStatusPayload>;
};


export type MutationCreateMealPlanArgs = {
  input: CreateMealPlanInput;
};


export type MutationTrackHealthProgressArgs = {
  input: TrackHealthProgressInput;
};


export type MutationUpdateHealthProfileArgs = {
  input: UpdateHealthProfileInput;
};


export type MutationUpdateHealthProgressArgs = {
  input: UpdateHealthProgressInput;
};


export type MutationUpdateMealArgs = {
  input: UpdateMealInput;
};


export type MutationUpdateOnboardingStatusArgs = {
  input: UpdateOnboardingStatusInput;
};

export type Part = {
  __typename?: 'Part';
  id: Scalars['ID'];
  ingredients: Array<Ingredient>;
  name?: Maybe<Scalars['String']>;
};

export type Protein = {
  __typename?: 'Protein';
  gm: Scalars['Float'];
  ratio: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns content to show on user's home screen */
  homescreen: Homescreen;
  /** Returns full list of content library */
  library: Library;
  /** Returns current user's meal guide inputs */
  macros: Macros;
  /** Returns current user */
  me?: Maybe<User>;
  /** Returns meal by id */
  meal: Meal;
  /** return a meal guide */
  mealPlan: MealPlan;
  /** Returns recipe by id */
  recipe: Recipe;
  /** Returns all settings */
  settings: Settings;
};


export type QueryMacrosArgs = {
  diet: Diet;
};


export type QueryMealArgs = {
  id: Scalars['ID'];
};


export type QueryMealPlanArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRecipeArgs = {
  id: Scalars['ID'];
};

export type QuickAction = {
  __typename?: 'QuickAction';
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link: Scalars['String'];
  title: Scalars['String'];
};

export type Recipe = {
  __typename?: 'Recipe';
  duration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  instructions: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  numServings?: Maybe<Scalars['Int']>;
  parts: Array<Part>;
  servingCalories?: Maybe<Scalars['Float']>;
  servingFats?: Maybe<Scalars['Float']>;
  servingNetCarbs?: Maybe<Scalars['Float']>;
  servingProtein?: Maybe<Scalars['Float']>;
};


export type RecipeImageUrlArgs = {
  variant?: Maybe<ImageVariant>;
};

export type Settings = {
  __typename?: 'Settings';
  chatAwayMessage: Scalars['String'];
  flaggedKeywords: Array<Scalars['String']>;
  id: Scalars['ID'];
  ineligibleStates: Array<Scalars['String']>;
  intakeFormBehavioralTypeformId: Scalars['String'];
  intakeFormMedicalTypeformId: Scalars['String'];
  mobileAppVersionCurrent: Scalars['String'];
  mobileAppVersionMinRequired: Scalars['String'];
};

export type StreamChat = {
  __typename?: 'StreamChat';
  accessToken: Scalars['String'];
  channelId: Scalars['String'];
  userId: Scalars['String'];
};

/** Autogenerated input type of TrackHealthProgress */
export type TrackHealthProgressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
};

/** Autogenerated return type of TrackHealthProgress. */
export type TrackHealthProgressPayload = {
  __typename?: 'TrackHealthProgressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  measurement: Measurement;
};

export enum UnitsSystem {
  /** Imperial */
  Imperial = 'imperial',
  /** Metric */
  Metric = 'metric'
}

/** Autogenerated input type of UpdateHealthProfile */
export type UpdateHealthProfileInput = {
  activityLevel: ActivityLevel;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentWeight: Scalars['Float'];
  healthGoal: HealthGoal;
};

/** Autogenerated return type of UpdateHealthProfile. */
export type UpdateHealthProfilePayload = {
  __typename?: 'UpdateHealthProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  healthProfile: HealthProfile;
};

/** Autogenerated input type of UpdateHealthProgress */
export type UpdateHealthProgressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
};

/** Autogenerated return type of UpdateHealthProgress. */
export type UpdateHealthProgressPayload = {
  __typename?: 'UpdateHealthProgressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  measurement: Measurement;
};

/** Autogenerated input type of UpdateMeal */
export type UpdateMealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  eaten?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  recipeId?: Maybe<Scalars['ID']>;
  skipped?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateMeal. */
export type UpdateMealPayload = {
  __typename?: 'UpdateMealPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  meal: Meal;
};

/** Autogenerated input type of UpdateOnboardingStatus */
export type UpdateOnboardingStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  complete: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateOnboardingStatus. */
export type UpdateOnboardingStatusPayload = {
  __typename?: 'UpdateOnboardingStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Returns current user */
  me: User;
};

export type User = {
  __typename?: 'User';
  careTeam?: Maybe<CareTeam>;
  careTeamMembers: Array<CareTeamMember>;
  chat?: Maybe<MaybeChat>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  healthProfile?: Maybe<HealthProfile>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  onboardingComplete: Scalars['Boolean'];
  preferredUnits?: Maybe<UnitsSystem>;
  streamChat?: Maybe<MaybeStreamChat>;
};

export type Week = {
  __typename?: 'Week';
  days: Array<Day>;
  id: Scalars['ID'];
};

export type Weight = {
  __typename?: 'Weight';
  humanFormat: Scalars['String'];
  inPreferredUnits: Scalars['Float'];
  kg: Scalars['Float'];
  lbs: Scalars['Float'];
  unit: WeightUnit;
};

export enum WeightUnit {
  Kg = 'kg',
  Lbs = 'lbs'
}

export type HealthProfileFieldsFragment = { __typename?: 'HealthProfile', id: string, activityLevel?: ActivityLevel | null | undefined, healthGoal?: HealthGoal | null | undefined, currentWeight?: { __typename?: 'Weight', humanFormat: string, lbs: number, kg: number, inPreferredUnits: number, unit: WeightUnit } | null | undefined };

export type MeasurementFieldsFragment = { __typename?: 'Measurement', id: string, notes?: string | null | undefined, measuredAt: any, weight: { __typename?: 'Weight', humanFormat: string, lbs: number, kg: number, inPreferredUnits: number, unit: WeightUnit } };

export type WeightFieldsFragment = { __typename?: 'Weight', humanFormat: string, lbs: number, kg: number, inPreferredUnits: number, unit: WeightUnit };

export type CreateMealPlanMutationVariables = Exact<{
  restrictions: Scalars['JSON'];
  dietType: Diet;
  start: Scalars['ISO8601Date'];
}>;


export type CreateMealPlanMutation = { __typename?: 'Mutation', createMealPlan?: { __typename?: 'CreateMealPlanPayload', mealPlan: { __typename?: 'MealPlan', uuid?: string | null | undefined } } | null | undefined };

export type TrackHealthProgressMutationVariables = Exact<{
  weight: Scalars['Float'];
  notes: Scalars['String'];
}>;


export type TrackHealthProgressMutation = { __typename?: 'Mutation', trackHealthProgress?: { __typename?: 'TrackHealthProgressPayload', measurement: { __typename?: 'Measurement', id: string, notes?: string | null | undefined, measuredAt: any, weight: { __typename?: 'Weight', humanFormat: string, lbs: number, kg: number, inPreferredUnits: number, unit: WeightUnit } } } | null | undefined };

export type UpdateHealthProfileMutationVariables = Exact<{
  currentWeight: Scalars['Float'];
  activityLevel: ActivityLevel;
  healthGoal: HealthGoal;
}>;


export type UpdateHealthProfileMutation = { __typename?: 'Mutation', updateHealthProfile?: { __typename?: 'UpdateHealthProfilePayload', healthProfile: { __typename?: 'HealthProfile', id: string, activityLevel?: ActivityLevel | null | undefined, healthGoal?: HealthGoal | null | undefined, currentWeight?: { __typename?: 'Weight', humanFormat: string, lbs: number, kg: number, inPreferredUnits: number, unit: WeightUnit } | null | undefined } } | null | undefined };

export type UpdateHealthProgressMutationVariables = Exact<{
  id: Scalars['Int'];
  weight: Scalars['Float'];
  notes: Scalars['String'];
}>;


export type UpdateHealthProgressMutation = { __typename?: 'Mutation', updateHealthProgress?: { __typename?: 'UpdateHealthProgressPayload', measurement: { __typename?: 'Measurement', id: string, notes?: string | null | undefined, measuredAt: any, weight: { __typename?: 'Weight', humanFormat: string, lbs: number, kg: number, inPreferredUnits: number, unit: WeightUnit } } } | null | undefined };

export type UpdateOnboardingStatusMutationVariables = Exact<{
  complete: Scalars['Boolean'];
}>;


export type UpdateOnboardingStatusMutation = { __typename?: 'Mutation', updateOnboardingStatus?: { __typename?: 'UpdateOnboardingStatusPayload', me: { __typename?: 'User', firstName?: string | null | undefined, onboardingComplete: boolean } } | null | undefined };

export type FetchCareTeamBusinessHoursQueryVariables = Exact<{
  time: Scalars['ISO8601DateTime'];
}>;


export type FetchCareTeamBusinessHoursQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, careTeam?: { __typename?: 'CareTeam', id: string, withinBusinessHours?: boolean | null | undefined, businessHours?: string | null | undefined } | null | undefined } | null | undefined };

export type FetchCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCoursesQuery = { __typename?: 'Query', library: { __typename?: 'Library', courses: Array<{ __typename?: 'Course', name: string, slug: string, coverImageUrl?: string | null | undefined }>, resources: Array<{ __typename?: 'Course', name: string, slug: string, coverImageUrl?: string | null | undefined }> } };

export type FetchCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCurrentUserQuery = { __typename?: 'Query', me?: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, gender?: Gender | null | undefined, preferredUnits?: UnitsSystem | null | undefined, healthProfile?: { __typename?: 'HealthProfile', id: string, activityLevel?: ActivityLevel | null | undefined, healthGoal?: HealthGoal | null | undefined, currentWeight?: { __typename?: 'Weight', humanFormat: string, lbs: number, kg: number, inPreferredUnits: number, unit: WeightUnit } | null | undefined } | null | undefined } | null | undefined };

export type FetchCurrentUserChatQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCurrentUserChatQuery = { __typename?: 'Query', me?: { __typename?: 'User', chat?: { __typename?: 'Chat', appId: string, userId: string, accessToken: string, channelUrl: string } | { __typename?: 'Error', key?: MessageKey | null | undefined } | null | undefined } | null | undefined };

export type FetchHomescreenQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchHomescreenQuery = { __typename?: 'Query', homescreen: { __typename?: 'Homescreen', contentFeeds?: Array<{ __typename?: 'ContentFeed', title: string, description: string, items: Array<{ __typename?: 'ContentFeedItem', id: string, imageUrl: string, description: string, title: string, type: string, url: string }> }> | null | undefined, quickActions: Array<{ __typename?: 'QuickAction', title: string, link: string }> } };

export type FetchMacrosQueryVariables = Exact<{
  diet: Diet;
}>;


export type FetchMacrosQuery = { __typename?: 'Query', macros: { __typename?: 'Macros', calories: number, diet: Diet, carbs: { __typename?: 'Carbs', gm: number, ratio: number }, protein: { __typename?: 'Protein', gm: number, ratio: number }, fats: { __typename?: 'Fats', gm: number, ratio: number } } };

export const WeightFieldsFragmentDoc = gql`
    fragment weightFields on Weight {
  humanFormat
  lbs
  kg
  inPreferredUnits
  unit
}
    `;
export const HealthProfileFieldsFragmentDoc = gql`
    fragment healthProfileFields on HealthProfile {
  id
  currentWeight {
    ...weightFields
  }
  activityLevel
  healthGoal
}
    ${WeightFieldsFragmentDoc}`;
export const MeasurementFieldsFragmentDoc = gql`
    fragment measurementFields on Measurement {
  id
  weight {
    ...weightFields
  }
  notes
  measuredAt
}
    ${WeightFieldsFragmentDoc}`;
export const CreateMealPlanDocument = gql`
    mutation createMealPlan($restrictions: JSON!, $dietType: Diet!, $start: ISO8601Date!) {
  createMealPlan(
    input: {restrictions: $restrictions, dietType: $dietType, start: $start}
  ) {
    mealPlan {
      uuid
    }
  }
}
    `;
export type CreateMealPlanMutationFn = Apollo.MutationFunction<CreateMealPlanMutation, CreateMealPlanMutationVariables>;

/**
 * __useCreateMealPlanMutation__
 *
 * To run a mutation, you first call `useCreateMealPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMealPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMealPlanMutation, { data, loading, error }] = useCreateMealPlanMutation({
 *   variables: {
 *      restrictions: // value for 'restrictions'
 *      dietType: // value for 'dietType'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useCreateMealPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMealPlanMutation, CreateMealPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMealPlanMutation, CreateMealPlanMutationVariables>(CreateMealPlanDocument, options);
      }
export type CreateMealPlanMutationHookResult = ReturnType<typeof useCreateMealPlanMutation>;
export type CreateMealPlanMutationResult = Apollo.MutationResult<CreateMealPlanMutation>;
export type CreateMealPlanMutationOptions = Apollo.BaseMutationOptions<CreateMealPlanMutation, CreateMealPlanMutationVariables>;
export const TrackHealthProgressDocument = gql`
    mutation trackHealthProgress($weight: Float!, $notes: String!) {
  trackHealthProgress(input: {weight: $weight, notes: $notes}) {
    measurement {
      ...measurementFields
    }
  }
}
    ${MeasurementFieldsFragmentDoc}`;
export type TrackHealthProgressMutationFn = Apollo.MutationFunction<TrackHealthProgressMutation, TrackHealthProgressMutationVariables>;

/**
 * __useTrackHealthProgressMutation__
 *
 * To run a mutation, you first call `useTrackHealthProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackHealthProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackHealthProgressMutation, { data, loading, error }] = useTrackHealthProgressMutation({
 *   variables: {
 *      weight: // value for 'weight'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useTrackHealthProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrackHealthProgressMutation, TrackHealthProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TrackHealthProgressMutation, TrackHealthProgressMutationVariables>(TrackHealthProgressDocument, options);
      }
export type TrackHealthProgressMutationHookResult = ReturnType<typeof useTrackHealthProgressMutation>;
export type TrackHealthProgressMutationResult = Apollo.MutationResult<TrackHealthProgressMutation>;
export type TrackHealthProgressMutationOptions = Apollo.BaseMutationOptions<TrackHealthProgressMutation, TrackHealthProgressMutationVariables>;
export const UpdateHealthProfileDocument = gql`
    mutation updateHealthProfile($currentWeight: Float!, $activityLevel: ActivityLevel!, $healthGoal: HealthGoal!) {
  updateHealthProfile(
    input: {currentWeight: $currentWeight, activityLevel: $activityLevel, healthGoal: $healthGoal}
  ) {
    healthProfile {
      ...healthProfileFields
    }
  }
}
    ${HealthProfileFieldsFragmentDoc}`;
export type UpdateHealthProfileMutationFn = Apollo.MutationFunction<UpdateHealthProfileMutation, UpdateHealthProfileMutationVariables>;

/**
 * __useUpdateHealthProfileMutation__
 *
 * To run a mutation, you first call `useUpdateHealthProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthProfileMutation, { data, loading, error }] = useUpdateHealthProfileMutation({
 *   variables: {
 *      currentWeight: // value for 'currentWeight'
 *      activityLevel: // value for 'activityLevel'
 *      healthGoal: // value for 'healthGoal'
 *   },
 * });
 */
export function useUpdateHealthProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateHealthProfileMutation, UpdateHealthProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateHealthProfileMutation, UpdateHealthProfileMutationVariables>(UpdateHealthProfileDocument, options);
      }
export type UpdateHealthProfileMutationHookResult = ReturnType<typeof useUpdateHealthProfileMutation>;
export type UpdateHealthProfileMutationResult = Apollo.MutationResult<UpdateHealthProfileMutation>;
export type UpdateHealthProfileMutationOptions = Apollo.BaseMutationOptions<UpdateHealthProfileMutation, UpdateHealthProfileMutationVariables>;
export const UpdateHealthProgressDocument = gql`
    mutation updateHealthProgress($id: Int!, $weight: Float!, $notes: String!) {
  updateHealthProgress(input: {id: $id, weight: $weight, notes: $notes}) {
    measurement {
      ...measurementFields
    }
  }
}
    ${MeasurementFieldsFragmentDoc}`;
export type UpdateHealthProgressMutationFn = Apollo.MutationFunction<UpdateHealthProgressMutation, UpdateHealthProgressMutationVariables>;

/**
 * __useUpdateHealthProgressMutation__
 *
 * To run a mutation, you first call `useUpdateHealthProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthProgressMutation, { data, loading, error }] = useUpdateHealthProgressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      weight: // value for 'weight'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateHealthProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateHealthProgressMutation, UpdateHealthProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateHealthProgressMutation, UpdateHealthProgressMutationVariables>(UpdateHealthProgressDocument, options);
      }
export type UpdateHealthProgressMutationHookResult = ReturnType<typeof useUpdateHealthProgressMutation>;
export type UpdateHealthProgressMutationResult = Apollo.MutationResult<UpdateHealthProgressMutation>;
export type UpdateHealthProgressMutationOptions = Apollo.BaseMutationOptions<UpdateHealthProgressMutation, UpdateHealthProgressMutationVariables>;
export const UpdateOnboardingStatusDocument = gql`
    mutation updateOnboardingStatus($complete: Boolean!) {
  updateOnboardingStatus(input: {complete: $complete}) {
    me {
      firstName
      onboardingComplete
    }
  }
}
    `;
export type UpdateOnboardingStatusMutationFn = Apollo.MutationFunction<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;

/**
 * __useUpdateOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStatusMutation, { data, loading, error }] = useUpdateOnboardingStatusMutation({
 *   variables: {
 *      complete: // value for 'complete'
 *   },
 * });
 */
export function useUpdateOnboardingStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>(UpdateOnboardingStatusDocument, options);
      }
export type UpdateOnboardingStatusMutationHookResult = ReturnType<typeof useUpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationResult = Apollo.MutationResult<UpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;
export const FetchCareTeamBusinessHoursDocument = gql`
    query fetchCareTeamBusinessHours($time: ISO8601DateTime!) {
  me {
    id
    careTeam {
      id
      withinBusinessHours(time: $time)
      businessHours
    }
  }
}
    `;

/**
 * __useFetchCareTeamBusinessHoursQuery__
 *
 * To run a query within a React component, call `useFetchCareTeamBusinessHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCareTeamBusinessHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCareTeamBusinessHoursQuery({
 *   variables: {
 *      time: // value for 'time'
 *   },
 * });
 */
export function useFetchCareTeamBusinessHoursQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FetchCareTeamBusinessHoursQuery, FetchCareTeamBusinessHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FetchCareTeamBusinessHoursQuery, FetchCareTeamBusinessHoursQueryVariables>(FetchCareTeamBusinessHoursDocument, options);
      }
export function useFetchCareTeamBusinessHoursLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCareTeamBusinessHoursQuery, FetchCareTeamBusinessHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FetchCareTeamBusinessHoursQuery, FetchCareTeamBusinessHoursQueryVariables>(FetchCareTeamBusinessHoursDocument, options);
        }
export type FetchCareTeamBusinessHoursQueryHookResult = ReturnType<typeof useFetchCareTeamBusinessHoursQuery>;
export type FetchCareTeamBusinessHoursLazyQueryHookResult = ReturnType<typeof useFetchCareTeamBusinessHoursLazyQuery>;
export type FetchCareTeamBusinessHoursQueryResult = Apollo.QueryResult<FetchCareTeamBusinessHoursQuery, FetchCareTeamBusinessHoursQueryVariables>;
export const FetchCoursesDocument = gql`
    query fetchCourses {
  library {
    courses {
      name
      slug
      coverImageUrl
    }
    resources {
      name
      slug
      coverImageUrl
    }
  }
}
    `;

/**
 * __useFetchCoursesQuery__
 *
 * To run a query within a React component, call `useFetchCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchCoursesQuery, FetchCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FetchCoursesQuery, FetchCoursesQueryVariables>(FetchCoursesDocument, options);
      }
export function useFetchCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCoursesQuery, FetchCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FetchCoursesQuery, FetchCoursesQueryVariables>(FetchCoursesDocument, options);
        }
export type FetchCoursesQueryHookResult = ReturnType<typeof useFetchCoursesQuery>;
export type FetchCoursesLazyQueryHookResult = ReturnType<typeof useFetchCoursesLazyQuery>;
export type FetchCoursesQueryResult = Apollo.QueryResult<FetchCoursesQuery, FetchCoursesQueryVariables>;
export const FetchCurrentUserDocument = gql`
    query fetchCurrentUser {
  me {
    email
    firstName
    lastName
    gender
    preferredUnits
    healthProfile {
      ...healthProfileFields
    }
  }
}
    ${HealthProfileFieldsFragmentDoc}`;

/**
 * __useFetchCurrentUserQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchCurrentUserQuery, FetchCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FetchCurrentUserQuery, FetchCurrentUserQueryVariables>(FetchCurrentUserDocument, options);
      }
export function useFetchCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCurrentUserQuery, FetchCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FetchCurrentUserQuery, FetchCurrentUserQueryVariables>(FetchCurrentUserDocument, options);
        }
export type FetchCurrentUserQueryHookResult = ReturnType<typeof useFetchCurrentUserQuery>;
export type FetchCurrentUserLazyQueryHookResult = ReturnType<typeof useFetchCurrentUserLazyQuery>;
export type FetchCurrentUserQueryResult = Apollo.QueryResult<FetchCurrentUserQuery, FetchCurrentUserQueryVariables>;
export const FetchCurrentUserChatDocument = gql`
    query fetchCurrentUserChat {
  me {
    chat {
      ... on Chat {
        appId
        userId
        accessToken
        channelUrl
      }
      ... on Error {
        key
      }
    }
  }
}
    `;

/**
 * __useFetchCurrentUserChatQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserChatQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserChatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchCurrentUserChatQuery, FetchCurrentUserChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FetchCurrentUserChatQuery, FetchCurrentUserChatQueryVariables>(FetchCurrentUserChatDocument, options);
      }
export function useFetchCurrentUserChatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCurrentUserChatQuery, FetchCurrentUserChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FetchCurrentUserChatQuery, FetchCurrentUserChatQueryVariables>(FetchCurrentUserChatDocument, options);
        }
export type FetchCurrentUserChatQueryHookResult = ReturnType<typeof useFetchCurrentUserChatQuery>;
export type FetchCurrentUserChatLazyQueryHookResult = ReturnType<typeof useFetchCurrentUserChatLazyQuery>;
export type FetchCurrentUserChatQueryResult = Apollo.QueryResult<FetchCurrentUserChatQuery, FetchCurrentUserChatQueryVariables>;
export const FetchHomescreenDocument = gql`
    query fetchHomescreen {
  homescreen {
    contentFeeds {
      title
      items {
        id
        imageUrl
        description
        title
        type
        url
      }
      description
    }
    quickActions {
      title
      link
    }
  }
}
    `;

/**
 * __useFetchHomescreenQuery__
 *
 * To run a query within a React component, call `useFetchHomescreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHomescreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHomescreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchHomescreenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchHomescreenQuery, FetchHomescreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FetchHomescreenQuery, FetchHomescreenQueryVariables>(FetchHomescreenDocument, options);
      }
export function useFetchHomescreenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchHomescreenQuery, FetchHomescreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FetchHomescreenQuery, FetchHomescreenQueryVariables>(FetchHomescreenDocument, options);
        }
export type FetchHomescreenQueryHookResult = ReturnType<typeof useFetchHomescreenQuery>;
export type FetchHomescreenLazyQueryHookResult = ReturnType<typeof useFetchHomescreenLazyQuery>;
export type FetchHomescreenQueryResult = Apollo.QueryResult<FetchHomescreenQuery, FetchHomescreenQueryVariables>;
export const FetchMacrosDocument = gql`
    query fetchMacros($diet: Diet!) {
  macros(diet: $diet) {
    calories
    carbs {
      gm
      ratio
    }
    protein {
      gm
      ratio
    }
    fats {
      gm
      ratio
    }
    diet
  }
}
    `;

/**
 * __useFetchMacrosQuery__
 *
 * To run a query within a React component, call `useFetchMacrosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMacrosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMacrosQuery({
 *   variables: {
 *      diet: // value for 'diet'
 *   },
 * });
 */
export function useFetchMacrosQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FetchMacrosQuery, FetchMacrosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FetchMacrosQuery, FetchMacrosQueryVariables>(FetchMacrosDocument, options);
      }
export function useFetchMacrosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchMacrosQuery, FetchMacrosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FetchMacrosQuery, FetchMacrosQueryVariables>(FetchMacrosDocument, options);
        }
export type FetchMacrosQueryHookResult = ReturnType<typeof useFetchMacrosQuery>;
export type FetchMacrosLazyQueryHookResult = ReturnType<typeof useFetchMacrosLazyQuery>;
export type FetchMacrosQueryResult = Apollo.QueryResult<FetchMacrosQuery, FetchMacrosQueryVariables>;