//@ts-expect-error SVG do not have types
import PersonIcon from 'images/icons/v2-person.svg'
import ChartIcon from 'images/webapp/chart.svg'
import FireIcon from 'images/webapp/fire.svg'
import WaterIcon from 'images/webapp/water.svg'
import React from 'react'

import CollapsibleTile from '../CollapsibleTile'
import MSRDButton from '../MSRDButton'
import P from '../Primatives'

interface Props {
  user?: any
  userTraits: any
}
const InfoPanel: React.FC<Props> = ({  userTraits }) => (
  <CollapsibleTile title="My Nutrition Plan" icon={PersonIcon}>
    <P.div className="info-panel">
      <div className="text-sm mb-2">
        <div className="flex flex-col md:flex-row gap-1 md:gap-2">
          <div className="flex gap-2 md:gap-1 items-center">
            <FireIcon className="h-16 w-12" />
            <div>
              <h4 className="font-semibold">Caloric Range:</h4>{userTraits.calories} kcal/day
            </div>
          </div>
          <div className="flex gap-2 md:gap-1 items-center">
            <WaterIcon className="h-16 w-12" />
            <div>
              <h4 className="font-semibold">Water Intake:</h4>{userTraits.water} liters per day
            </div>
          </div>
          <div className="flex mt-2 md:mt-0 gap-2 md:gap-1 items-center">
            <ChartIcon className="h-16 w-12" />
            <div>
              <h4 className="font-semibold">Macro Nutrients:</h4>
              <div className="flex flex-col md:flex-row md:gap-6">
                <div>
                  <span className="font-medium">Protein:</span> {userTraits.protein}g
                </div>
                <div>
                  <span className="font-medium">Carbs:</span> {userTraits.carbs}g
                </div>
                <div>
                  <span className="font-medium">Fat:</span> {userTraits.fats}g
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </P.div>
    <div className="flex items-center">
      <MSRDButton
        className={`w-full max-w-sm mx-auto`}
        onClick={() => {
          window.location.href = `/meal-plans/new`
        }}
      >
        Start A New Plan
      </MSRDButton>
    </div>
  </CollapsibleTile>
)

export default InfoPanel
