import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    archiveUrl: String,
  }
  archive() {
    // call the archive action on the controller
    // then remove the notification from the screen using the removeFromScreen method
    console.log(this.archiveUrlValue)
    fetch(this.archiveUrlValue, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': this.data.get('csrf-token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_notification: {
          archived: true,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.removeFromScreen()
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  removeFromScreen() {
    // fade out the parent div and remove it from the DOM that has a class of .user-notification
    this.element.classList.add('opacity-0')
    setTimeout(() => {
      this.element.remove()
    }, 500)
  }
}
