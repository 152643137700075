window.showMealFilters = () => {
  $('#filter-meal-plan').click(function (ev) {
    const target = $(ev.currentTarget).data('url')
    window.modalStartLoading()
    $.getScript(target)
    ev.preventDefault()
  })
}

window.showChangeStartDateModal = () => {
  $('#change-meal-plan-start-date').click(function (ev) {
    const target = $(ev.currentTarget).data('url')
    window.modalStartLoading()
    console.log(target)
    $.getScript(target)
    ev.preventDefault()
  })
}

$(document).ready(() => {
  $('#open-sidebar').click((ev) => {
    $('#sidebar-container').removeClass('hidden')
    setTimeout(() => {
      $('#sidebar-overlay').removeClass('opacity-0')
      $('#sidebar-menu').removeClass('-translate-x-full')
    }, 0)
    ev.preventDefault()
  })

  $('#close-sidebar, #sidebar-overlay').click((ev) => {
    $('#sidebar-overlay').addClass('opacity-0')
    $('#sidebar-menu').addClass('-translate-x-full')
    setTimeout(() => {
      $('#sidebar-container').addClass('hidden')
    }, 100)
    ev.preventDefault()
  })

  $('#profile-nav').click((ev) => {
    $('.profile-menu').toggleClass('profile-menu--active')
  })

  $('.panel-link').click((ev) => {
    showPanel('test', 'this is a test')

    ev.preventDefault()
  })

  $('.hide-panel').click((ev) => {
    window.hidePanel()
  })

  $('.close-modal').click(() => {
    window.hideModal && window.hideModal()
    window.hideDeleteModal && window.hideDeleteModal()
  })

  $('[data-f-id="pbf"]').hide()
})
