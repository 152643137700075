import React from 'react'

import { Tile } from '../Tile'
import { IMeasurementProps } from './types'
import { getWeight } from './utils'

export const OverallProgress = ({ measurementData }: IMeasurementProps) => {
  const { measurements, preferredUnits } = measurementData
  if (!measurements.length || measurements.length === 0) {
    return null
  }
  const measurementsSorted = measurements.sort(
    //@ts-expect-error Date conversion will not be broken
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  )

  const initialWeight = getWeight(measurementsSorted[0].weight, preferredUnits)
  const lastWeight = getWeight(
    measurementsSorted[measurementsSorted.length - 1].weight,
    preferredUnits
  )
  const diffWeight = lastWeight - initialWeight

  let showPlus = false

  if (diffWeight > 0) {
    showPlus = true
  }

  return (
    measurementsSorted.length > 0 && (
      <div>
        <h2 className="tile-heading">Overall Progress</h2>
        <Tile>
          <div className="p-2 grid gap-5 grid-cols-2 md:grid-cols-3">
            <div className="flex flex-col">
              <h3 className="m-0 p-0 uppercase text-gray-400 text-xs">
                Initial Weight
              </h3>
              <div className="flex gap-2 text-2xl">
                <span>{initialWeight}</span>
                <span>{preferredUnits}</span>
              </div>
            </div>

            <div className="flex flex-col">
              <h3 className="m-0 p-0 uppercase text-gray-400 text-xs">
                Current Weight
              </h3>
              <div className="flex gap-2 text-2xl">
                <span>{lastWeight}</span>
                <span>{preferredUnits}</span>
              </div>
            </div>

            <div className="flex flex-col">
              <h3 className="m-0 p-0 uppercase text-gray-400 text-xs">
                Total Change
              </h3>
              <div className="flex gap-2 text-2xl">
                <span>
                  {showPlus && '+'}
                  {diffWeight}
                </span>
                <span>{preferredUnits}</span>
              </div>
            </div>
          </div>
        </Tile>
      </div>
    )
  )
}
