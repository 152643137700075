// app/javascript/controllers/subscription_change_reason_controller.js
import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    reasons: Object,
    trackUrl: String,
  }
  static targets = ['primary', 'secondary']

  connect() {
    this.toggleSecondaryVisibility()
    this.secondaryTarget.addEventListener('change', () =>
      this.trackReasonSelection('secondary')
    )
  }

  updateSecondaryOptions() {
    this.trackReasonSelection('primary')
    const primaryReason = this.primaryTarget.value
    if (primaryReason) {
      const secondaryOptions = this.reasonsValue[primaryReason] || []
      this.populateSecondaryOptions(secondaryOptions)
      this.secondaryTarget.classList.remove('hidden') // Show the secondary wrapper
    } else {
      this.secondaryTarget.classList.add('hidden') // Hide the secondary wrapper if primary reason is not selected
    }
  }

  populateSecondaryOptions(options) {
    const secondarySelect = this.secondaryTarget.querySelector('select')
    secondarySelect.innerHTML = '<option value="">Please select...</option>' // Reset and add default option

    options.forEach((option) => {
      const optionElement = document.createElement('option')
      optionElement.value = option
      optionElement.textContent = option
      secondarySelect.appendChild(optionElement)
    })
  }

  toggleSecondaryVisibility() {
    const primaryReason = this.primaryTarget.value
    if (primaryReason.length > 0) {
      this.secondaryTarget.classList.remove('hidden')
    } else {
      this.secondaryTarget.classList.add('hidden')
    }
  }

  trackReasonSelection(reason_selected) {
    const secondarySelect = this.secondaryTarget.querySelector('select')
    const url = new URL(this.trackUrlValue)
    url.searchParams.set('reason_selected', reason_selected)
    url.searchParams.set('primary_reason', this.primaryTarget.value)
    url.searchParams.set('secondary_reason', secondarySelect.value)

    post(url.toString(), { responseKind: 'turbo-stream' }).catch((error) =>
      console.error('Error Tracking Reason Selection:', error)
    )
  }
}
