import './modals'
var ProgressBar = require('progressbar.js')

window.updatePrintModal = (url) => {
  window.modalSetContent(
    `<div class="p-4 text-center flash">
      <h3 class="mb-4">Your Plan Is Ready</h3>
      <p class="text-lg">Your custom meal guide is ready to download. Please click the following button:</p>
      <div class="mt-5 sm:mt-6">
        <span class="flex w-full rounded-md shadow-sm">
          <a href="${url}" target="_blank" type="button" id="pdf-download-button" class="font-semibold text-xl inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 hover:text-white">Download Plan PDF</a>
        </span>
      </div>
    </div>`
  )
  setTimeout(() => {
    $('#pdf-download-button').click(() => {
      _cio && _cio.track('Meal Guide Download')
    })
  }, 1000)
}

window.checkPrintStatus = (target) => {
  setTimeout(() => {
    $.getJSON(`${target}/check_file_status`)
      .done(function (json) {
        console.log(json)
        if (json.status === 'processing') {
          console.log('Still processing...')
          window.checkPrintStatus(target)
        } else if (json.status === 'ready') {
          console.log('Finished!', json.pdf_url)
          $('#modal').removeClass('loading-pdf')
          window.updatePrintModal(json.pdf_url)
        }
      })
      .fail(function (jqxhr, textStatus, error) {
        window.showErrorModal()
        console.log('Request Failed: ' + error)
      })
  }, 1500)
}

window.setPrintLinks = () => {
  $('.print-link').click(function (ev) {
    const target = $(ev.currentTarget).data('url')

    $('#modal').addClass('loading-pdf')

    window.modalSetContent(
      '<div class="p-4 text-center"><h3 class="mb-4">Just a sec, we\'re getting it ready!</h3>' +
        '<p class="text-lg">We are formatting your meal guide and making it printer perfect. This process can take about 20-30 seconds.</p>' +
        '<div id="progress-bar" class="mx-10 my-5"></div>' +
        '</div>'
    )

    $.getJSON(`${target}/create_pdf`)
      .done(function (json) {
        console.log(json)
        if (json.status !== 'ok') {
          window.showErrorModal()
        }
      })
      .fail(function (jqxhr, textStatus, error) {
        window.showErrorModal()
        console.log('Request Failed: ' + error)
      })

    var bar = new ProgressBar.Line('#progress-bar', {
      strokeWidth: 6,
      easing: 'easeInOut',
      duration: 30000,
      color: '#ED6A5A',
      trailColor: '#eee',
      trailWidth: 4,
      svgStyle: { width: '100%', height: '100%' },
    })
    window.showModal()
    bar.animate(1)

    // Start polling
    window.checkPrintStatus(target)

    // failesafe:
    setTimeout(() => {
      if ($('#modal').hasClass('loading-pdf')) {
        window.showErrorModal()
      }
    }, 80000)

    ev.preventDefault()
  })
}
