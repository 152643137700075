import React from 'react'

import { IScreenProp } from '../../types'
import { Chart } from './Chart'
import { OverallProgress } from './OverallProgress'
import { ProgressWrapper } from './ProgressWrapper'
import { WeightHistory } from './WeightHistory'

export const Progress = ({
  measurementData,
  ...rest
}: Partial<IScreenProp>) => (
  <ProgressWrapper {...rest}>
    <div className="flex flex-col gap-10">
      <Chart measurementData={measurementData} />
      <OverallProgress measurementData={measurementData} />
      <WeightHistory measurementData={measurementData} />
    </div>
  </ProgressWrapper>
)
