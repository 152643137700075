import * as React from 'react'

import P from '../Primatives'
import { ILessonListProps } from './types'

export const LessonList = ({
  ...props
}: ILessonListProps) => {
  return (
    <>
      {props.content.map((lesson, index) => (
        <div key={lesson.slug}>
          <LessonItem
            heading={`Lesson ${index + 1}`}
            title={lesson.name}
            imageUrl={lesson.coverImageUrl}
            contentPath={`/courses/${lesson.slug}`}
          />
        </div>
      ))}
    </>
  )
}

const LessonItem = ({ heading = null, title, imageUrl, contentPath }) => {
  return (
    <a
      href={contentPath}
      className="msrd-card flex flex-row space-x-2 lg:space-x-3 items-center"
    >
      <P.div className="w-1/4">
        <img src={imageUrl} className="w-full object-cover" />
      </P.div>
      <P.div className="w-3/4">
        {heading && (
          <div className="text-xs lg:text-sm uppercase font-semibold">
            {heading}
          </div>
        )}
        <h3 className="text-lg lg:text-xl font-semibold leading-6">{title}</h3>
      </P.div>
    </a>
  )
}
