import React, { useEffect, useState } from 'react'
import {
  MessageList as StreamChatMessageList,
  useChannelStateContext,
} from 'stream-chat-react'

import { useChat } from '../contexts/chatContext'
import { IStreamMessage } from '../types'
import { differenceInHours } from './Message/helpers'

export default function MessageList() {
  const { messages } = useChannelStateContext()
  const [messageList, setMessageList] = useState([])
  const { customerUserId } = useChat()

  useEffect(() => {
    const messagesFormatted = []

    const uniqueData: IStreamMessage[] = [
      ...messages.filter(
        (message, index, self) =>
          message.type !== 'deleted' &&
          self.findIndex((m) => m.id === message.id) === index
      ),
    ]

    const messagesFiltered = [...uniqueData]

    let currentUserFirstMessage = null

    for (let i = 0; i < messagesFiltered.length; i++) {
      const currentMessage = messagesFiltered[i]
      // Group messages by user
      if (currentMessage.user?.id === currentUserFirstMessage?.user?.id) {
        const isLessthan1Hour =
          differenceInHours(
            currentUserFirstMessage.created_at,
            currentMessage.created_at
          ) < 1

        // if the difference between the current message and the previous message is less than 1 hour
        // we hide the header
        if (isLessthan1Hour) {
          currentMessage.hide_header = true
        } else {
          addSysMessageForCustomer(
            currentMessage,
            currentUserFirstMessage,
            messagesFormatted
          )
          currentUserFirstMessage = currentMessage
        }
      } else {
        // before change, we try to add a system message in case the messages of the before user are under 1 hour
        addSysMessageForCustomer(
          currentMessage,
          currentUserFirstMessage,
          messagesFormatted
        )

        if (!!currentUserFirstMessage) {
          messagesFormatted.push({
            custom_type: 'separator',
          })
        }

        currentUserFirstMessage = currentMessage
      }

      messagesFormatted.push(currentMessage)
    }

    // in case the last message is from the customer, we try to add a system message in case there were not added before
    if (messagesFiltered.length > 0) {
      addSysMessageForCustomer(
        messagesFiltered[messagesFiltered.length - 1],
        currentUserFirstMessage,
        messagesFormatted,
        false
      )
    }
    setMessageList([...messagesFormatted])
  }, [messages])

  const addSysMessageForCustomer = (
    currentMessage,
    currentUserFirstMessage,
    messagesFormatted,
    withSeparator = true
  ) => {
    if (
      currentMessage.user?.id === customerUserId &&
      currentUserFirstMessage?.user?.id === currentMessage.user?.id &&
      messagesFormatted[messagesFormatted.length - 1].custom_type !== 'system'
    ) {
      messagesFormatted.push({
        custom_type: 'system',
        text: `Thanks for your message! A member of your care team will respond within 1-2 business days. If this is a an emergency, please call 911.`,
      })

      withSeparator &&
        messagesFormatted.push({
          custom_type: 'separator',
        })
    }
  }

  return (
    <StreamChatMessageList
      messages={messageList}
      disableDateSeparator
      hideDeletedMessages
      noGroupByUser
    />
  )
}
