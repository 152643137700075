import { Dropdown, Tabs } from 'tailwindcss-stimulus-components'

import Persona from '../../onboarding/controllers/persona_controller'
import Typeform from '../../onboarding/controllers/typeform'
import WelcomeCallCalendar from '../../onboarding/controllers/welcome-call-calendar'
import { application } from './application'
import ChatMessageButton from './chat_message_button_controller'
import ChatNewMessagesCard from './chat_new_messages_card_controller'
import ChatPage from './chat_page_controller'
import ExplorePage from './explore_page_controller'
import LessonPage from './lesson_page_controller'
import MealPlanInfoPlanel from './meal_plan_info_panel_controller'
import MealPlanNewForm from './meal_plan_new_form_controller'
import MenuController from './menu_controller'
import NearbyPharmacies from './nearby_pharmacies_controller'
import Print from './print_controller'
import ProgressPage from './progress_page_controller'
import UploadComponent from './upload_component_controller'
import UserNotifications from './user_notifications_controller'
import SubscriptionChangeReason from './subscription_change_reason_controller'
import DialogController from './dialog_controller'
import EventTrackerController from "../../controllers/event_tracker_controller";

application.register('dropdown', Dropdown)
application.register('chat-message-button', ChatMessageButton)
application.register('chat-page', ChatPage)
application.register('new-messages-card', ChatNewMessagesCard)
application.register('progress-page', ProgressPage)
application.register('explore-page', ExplorePage)
application.register('lesson-page', LessonPage)
application.register('meal-plan-info-panel', MealPlanInfoPlanel)
application.register('meal-plan-new-form', MealPlanNewForm)
application.register('welcome-call-calendar', WelcomeCallCalendar)
application.register('typeform', Typeform)
application.register('upload-component', UploadComponent)
application.register('print', Print)
application.register('menu', MenuController)
application.register('nearby-pharmacies', NearbyPharmacies)
application.register('persona', Persona)
application.register('user-notifications', UserNotifications)
application.register('tabs', Tabs)
application.register('subscription-change-reason', SubscriptionChangeReason)
application.register('dialog', DialogController)
application.register('event-tracker', EventTrackerController)
