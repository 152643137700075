import { useCreateMealPlanMutation } from 'graphql/types'
import * as React from 'react'
import { useEffect, useState } from 'react'

import AdjustMacrosStep from './AdjustMacrosStep'
import { MealPlanParams } from './index'
import SelectFoodsStep, { FoodRestrictions } from './SelectFoodsStep'
import SelectStartDateStep from './SelectStartDateStep'
import UpdateMeasurementsStep from './UpdateMeasurementsStep'

interface Props {
  currentWeight: number
  weightUnit: string
  foodRestrictions: FoodRestrictions
  url: string
}

export default function Form(props: Props) {
  const [step, setStep] = useState(0)
  const [createMealPlan, { loading, error, data }] = useCreateMealPlanMutation()
  const [params, setParams] = useState({} as MealPlanParams)

  const onNext = (newParams: MealPlanParams = {}) => {
    setParams({ ...params, ...newParams })
    setStep(step + 1)
  }

  useEffect(() => {
    if (data) {
      window.location.pathname = `/meal-plans/${data.createMealPlan.mealPlan.uuid}`
    }
  }, [data])

  const onSubmit = (newParams: MealPlanParams) => {
    const variables = {
      restrictions: JSON.stringify(params.restrictions),
      dietType: params.dietType,
      start: newParams.start,
    }

    createMealPlan({ variables })
  }

  const steps = [
    // eslint-disable-next-line react/jsx-key
    <UpdateMeasurementsStep
      currentWeight={props.currentWeight}
      stepNumber={step + 1}
      weightUnit={props.weightUnit}
      onNext={onNext}
    />,
    // eslint-disable-next-line react/jsx-key
    <AdjustMacrosStep stepNumber={step + 1} onNext={onNext} />,
    // eslint-disable-next-line react/jsx-key
    <SelectFoodsStep
      stepNumber={step + 1}
      foodRestrictions={props.foodRestrictions}
      onNext={onNext}
    />,
    // eslint-disable-next-line react/jsx-key
    <SelectStartDateStep
      stepNumber={step + 1}
      onSubmit={onSubmit}
      disableSubmit={loading || !!data}
      submissionError={!!error}
    />,
  ]

  return <>{steps[step]}</>
}
