import { useChat } from 'components/Chat/contexts/chatContext'
import React from 'react'

import { RegularMessageBody } from './Body'
import { RegularMessageHeader } from './Header'

export default function RegularMessage() {
  const { customerUserId } = useChat()

  return (
    <div className="flex flex-col">
      <RegularMessageHeader customerUserId={customerUserId} />
      <RegularMessageBody customerUserId={customerUserId} />
    </div>
  )
}
