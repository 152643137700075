import * as Sentry from '@sentry/react'
import * as React from 'react'

import { IScreenProp } from '../../types'
import Provider from '../../util/hoc/Provider'
import { Library } from '.'

const LibraryPage = (props: Partial<IScreenProp>) => {
  return <Library  {...props} />
}

export default Provider(
  Sentry.withErrorBoundary(LibraryPage, {
    fallback: <p>An error has occurred</p>,
  })
)
