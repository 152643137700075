import React from 'react'

import { DomainChart } from './types'

interface ICurrentRangeProp {
  zoomDomain: DomainChart
}
export const CurrentRange = ({ zoomDomain }: ICurrentRangeProp) => (
  <div className="m-2 uppercase font-semibold flex justify-center gap-2 py-2">
    <p>
      {(zoomDomain.x[0] as Date).toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
      })}
    </p>
    <p>-</p>
    <p>
      {(zoomDomain.x[1] as Date).toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
      })}
    </p>
  </div>
)
