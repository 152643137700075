import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

export const createCache = () => {
  const cache = new InMemoryCache()
  if (process.env.NODE_ENV === 'development') {
    window.secretVariableToStoreCache = cache
  }
  return cache
}

const getCSRFToken = () =>
  document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
const token = getCSRFToken()
const setCSRFTokenForOperation = async (operation) =>
  operation.setContext({
    headers: {
      'X-CSRF-Token': token,
    },
  })

// link with token
const createLinkWithToken = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle
        Promise.resolve(operation)
          .then(setCSRFTokenForOperation)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            })
          })
          .catch(observer.error.bind(observer))
        return () => {
          if (handle) handle.unsubscribe()
        }
      })
  )

// log errors
const logError = (error) => {
  // TODO for production and staging use Airbrake to report errors here.
  console.error(error)
}

// create error link
const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      logError('GraphQL - Error', {
        errors: graphQLErrors,
        operationName: operation.operationName,
        variables: operation.variables,
      })
    }
    if (networkError) {
      logError('GraphQL - NetworkError', networkError)
    }
  })

const createHttpLink = () =>
  new HttpLink({
    uri: '/graphql',
    credentials: 'include',
  })

export const createClient = (cache, requestLink) => {
  return new ApolloClient({
    link: ApolloLink.from([
      createErrorLink(),
      createLinkWithToken(),
      createHttpLink(),
    ]),
    cache,
  })
}
