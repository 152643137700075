import { Diet } from 'graphql/types'
import * as React from 'react'
import Provider from 'util/hoc/Provider'

import Form from './Form'
import { FoodRestrictions } from './SelectFoodsStep'

export interface MealPlanParams {
  restrictions?: FoodRestrictions
  dietType?: Diet
  start?: string
}

function NewMealPlanForm(props) {
  return (
    <div>
      <Form {...props} />
    </div>
  )
}

export default Provider(NewMealPlanForm)
