import React from 'react'

import { IScreenProp } from '../../types'
import P from '../Primatives'
import { CourseWrapper } from './CourseWrapper'

export const CourseScreen = ({
  lessonData,
  ...props
}: Partial<IScreenProp>) => {
  const { imageTagHtml, name, contentTagHtml, next, previous } = lessonData

  return (
    <CourseWrapper {...props}>
      <P.div>
        <div className="container mx-auto my-3">
          <div className="mx-auto">
            <div className="msrd-card">
              <div dangerouslySetInnerHTML={{ __html: imageTagHtml }} />
              <div className="lesson-content p-3 lg:p-8">
                <h2 className="my-6 font-serif text-center font-medium">
                  {name}
                </h2>
                <div className="bg-blue-200 h-1 w-32 mx-auto mb-8"></div>
                <div className="lesson-content lg:text-lg fr-view">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contentTagHtml.replace(
                        /<p>(Powered by <a+)((\s)+(.+)+)+<\/p>/g,
                        ''
                      ),
                    }}
                  />
                </div>
                <div className="flex justify-between items-center mt-10">
                  <div>
                    {!!previous && (
                      <a
                        href={previous}
                        className="msrd-button msrd-button-secondary"
                      >
                        &larr; Previous
                      </a>
                    )}
                  </div>
                  <div>
                    {!!next && (
                      <a
                        href={next}
                        className="msrd-button msrd-button-secondary"
                      >
                        Next &rarr;
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </P.div>
    </CourseWrapper>
  )
}
