export const getHeaders = () => {
  let headers = new window.Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  })

  const csrfToken = document.head.querySelector("[name='csrf-token']")
  if (csrfToken) {
    headers.append('X-CSRF-Token', csrfToken.content)
  }

  return headers
}

export const createRequest = (url, method = 'get') => {
  const request = new window.Request(url, {
    headers: getHeaders(),
    method: method,
    credentials: 'same-origin',
    dataType: 'json',
  })
  return request
}
