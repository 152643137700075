import { add, format, parse } from 'date-fns'

const loadRecipe = (ev) => {
  ev.preventDefault()

  const recipeId = $(ev.currentTarget).data('recipe-id')
  const mealId = $(ev.currentTarget).data('meal-id')
  const title = $(ev.currentTarget).data('title')

  window.panelStartLoading()

  if (_cio) {
    _cio.track('Recipe View', {
      recipe_id: recipeId,
    })
  }

  $.ajax({
    method: 'GET',
    url: `/recipes/${recipeId}/modal`,
    success: (html) => {
      window.panelSetContent(title, html)
      $('#panel-content .swap-recipe-link')
        .attr('data-meal-id', mealId)
        .click(loadSwapRecipe)
      window.panelStopLoading()
    },
  })
}

const replaceRecipe = (ev) => {
  const mealId = $(ev.currentTarget).data('meal-id')
  const recipeId = $(ev.currentTarget).data('recipe-id')

  window.panelStartLoading()

  $.ajax({
    method: 'GET',
    url: `/meals/${mealId}/replace/${recipeId}`,
    success: (html) => {
      $(`*[data-meal-id="${mealId}"]`).replaceWith(html)
      $(`*[data-meal-id="${mealId}"] .recipe-link`).click(loadRecipe)
      $(`*[data-meal-id="${mealId}"] .swap-recipe-link`).click(loadSwapRecipe)
      $(`*[data-meal-id="${mealId}"] .meal-completion-button`).click(
        handleMealEatenToggle
      )
      window.hidePanel()
    },
  })

  ev.preventDefault()
}

const loadSwapRecipe = (ev) => {
  ev.preventDefault()

  const mealId = $(ev.currentTarget).data('meal-id')
  const title = 'Select an Alternative Recipe'

  window.panelStartLoading()
  $.ajax({
    method: 'GET',
    url: `/meals/${mealId}/get_alternatives`,
    success: (html) => {
      window.panelSetContent(title, html)
      window.panelStopLoading()
      $('.replace-recipe-link').click(replaceRecipe)
    },
  })
}

window.setMealPlanControls = () => {
  function onModalClick(event) {
    const id = event.currentTarget.id
    const mealId = event.currentTarget.getAttribute('data-meal-id')
    const type = event.currentTarget.classList.contains('replace-recipe')
      ? 'replace-recipe'
      : 'view-recipe'

    if (type === 'replace-recipe') {
      loadReplaceRecipe(id)
    } else {
      loadRecipe(id, mealId)
    }

    event.preventDefault()
    event.stopPropagation()
  }

  const overlay = document.querySelector('.modal-overlay')
  overlay.addEventListener('click', () => {
    closeModal()
  })

  const closemodal = document.querySelectorAll('.modal-close')
  for (let i = 0; i < closemodal.length; i++) {
    closemodal[i].addEventListener('click', () => {
      closeModal()
    })
  }

  window.replaceRecipe = (mealId, recipeId) => {
    console.log('Meal:', mealId)
    console.log('Recipe:', recipeId)
    $.ajax({
      method: 'GET',
      url: `/meals/${mealId}/replace/${recipeId}`,
      success: (html) => {
        $(`*[data-meal-id="${mealId}"]`).replaceWith(html)
        $(`*[data-meal-id="${mealId}"]`).click(onModalClick)
        $(`*[data-meal-id="${mealId}"] .modal-open`).click(onModalClick)
        $(`#meal-completion-button-${mealId}`).click(handleMealEatenToggle)
        closeModal()
      },
    })
  }

  document.onkeydown = (evt) => {
    evt = evt || window.event
    let isEscape = false

    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc'
    } else {
      isEscape = evt.keyCode === 27
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
      closeModal()
    }
  }

  const openModal = () => {
    $('.modal').removeClass('opacity-0')
    $('.modal').removeClass('pointer-events-none')
    $('body').addClass('modal-active')
  }

  const closeModal = () => {
    $('.modal').addClass('opacity-0')
    $('.modal').addClass('pointer-events-none')
    $('body').removeClass('modal-active')
    $('.modal-content-wrap').html('')
  }

  const todaysDate = new Date().getDay()
  const selectDate = (weekIndex, dateIndex) => {
    $(`.day-container`).removeClass('selected')
    $(
      `.week-container[data-week-id=${weekIndex}] .day-container[data-day-id=${dateIndex}]`
    ).addClass('selected')
    // $('.day').hide();
    // $(`.day-${dateIndex}`).fadeIn();
    // $('.print-link').attr('href', `${$('.print-link').data('url')}/print.pdf`)
  }

  $('.day-nav-item').click((event) => {
    const targetWeek = $(event.currentTarget).data('week-id')
    const targetDay = $(event.currentTarget).data('day-id')
    selectDate(targetWeek, targetDay)
  })

  const selectWeek = (weekIndex) => {
    $('.week-container').removeClass('selected')
    $(`.week-container[data-week-id=${weekIndex}]`).addClass('selected')

    $('.current-week').hide().html(weekIndex).fadeIn()
    $('#week-name').removeClass('flash')
    setTimeout(() => {
      $('#week-name').addClass('flash')
    }, 10)

    $('.grocery-list-link').attr(
      'href',
      `${$('.grocery-list-link').data('url')}/${weekIndex}/groceries`
    )
    selectDate(weekIndex, 1)

    const startDate = parse(
      $('#week-name').data('start-date'),
      'yyyy-MM-dd',
      new Date()
    )
    const endDate = add(startDate, { days: 6 })

    const startDateInterval = add(startDate, {
      weeks: weekIndex - 1,
    })

    const endDateInterval = add(endDate, {
      weeks: weekIndex - 1,
    })

    $('.current-week-start-date').html(
      '<span class="hidden sm:inline">Week of </span>' +
        format(startDateInterval, 'M/d')
    )
    $('.current-week-end-date').text(format(endDateInterval, 'M/d'))
  }

  const params = new URLSearchParams(window.location.search)
  const curWeek = params.has('week') ? parseInt(params.get('week')) : 1

  selectWeek(1)
  // selectDate(curWeek, todaysDate);

  const setWeekNav = () => {
    const numWeeks = parseInt($('#week-name').data('total-weeks'))

    $('.next-week').click((event) => {
      const currentWeek = parseInt($('.current-week').html())
      selectWeek(currentWeek < numWeeks ? currentWeek + 1 : 1)
    })

    $('.previous-week').click((event) => {
      const currentWeek = parseInt($('.current-week').html())
      selectWeek(currentWeek === 1 ? numWeeks : currentWeek - 1)
    })
  }

  setWeekNav()
}

const toggleMealItemLoading = (mealId, forceAction = null) => {
  const mealItem = $(`.meal-item[data-meal-id=${mealId}]`)

  if (forceAction === null) {
    mealItem.toggleClass('loading')
  } else if (forceAction === true) {
    mealItem.addClass('loading')
  } else if (forceAction === false) {
    mealItem.removeClass('loading')
  }
}

const handleMealEatenToggle = (ev) => {
  const mealId = $(ev.currentTarget).data('meal-id')
  const button = $('#meal-completion-button-' + mealId)
  const mealItem = $(`.meal-item[data-meal-id=${mealId}]`)

  toggleMealItemLoading(mealId)

  $.get(`/meals/${mealId}/toggle_completed`)
    .done((data) => {
      if (data.meal.completed) {
        button.addClass('completed')
        mealItem.addClass('completed')
      } else {
        button.removeClass('completed')
        mealItem.removeClass('completed')
      }
      toggleMealItemLoading(mealId, false)
    })
    .fail(() => {
      console.log('fail')
      toggleMealItemLoading(mealId, false)
    })

  ev.preventDefault()
}

window.setRecipeAndSwapLinks = () => {
  $('.recipe-link').click(loadRecipe)
  $('.swap-recipe-link').click(loadSwapRecipe)
  $('.meal-completion-button').click(handleMealEatenToggle)
}

window.loadMealPlan = (uuid) => {
  $.get(`/meal-plans/${uuid}/render`)
    .done((data) => {
      $('#loading').hide()
      $('#meal-plan-cont').html(data)
      window.setRecipeAndSwapLinks()
      window.setMealPlanControls()
    })
    .fail(() => {
      $('#loading').html('There was a problem loading the meal guide...')
    })
}
