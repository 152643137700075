import React, { useEffect } from 'react'
import { Channel, Thread, useChatContext, Window } from 'stream-chat-react'

import { IScreenProp } from '../../types'
import Message from './Components/Message'
import MessageInput from './Components/MessageInput'
import MessageList from './Components/MessageList'

export const Stream = ({
  streamData,
  isChannelListOpen,
  channelType,
}: Partial<IScreenProp>) => {
  const { client, channel, setActiveChannel, openMobileNav } = useChatContext()

  useEffect(() => {
    if (!!channel) {
      channel.stopWatching()
    }
    setActiveChannel(
      client.getChannelById(
        channelType,
        channelType !== 'medical'
          ? streamData.userData.channel_id
          : streamData.userData.medical_channel_id,
        {}
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  useEffect(() => {
    if (isChannelListOpen) {
      openMobileNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChannelListOpen])

  useEffect(() => {
    if (!!channel) {
      channel.watch().then(() => {
        channel.markRead()
      })
    }
  }, [channel])

  return (
    <div className="channel-conatiner flex-auto">
      <Channel Message={() => <Message />}>
        <Window>
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </div>
  )
}
