import { IChannelInfo } from './types'

const getMemberImages = (client, members) => {
  const images: string[] = []
  for (const key in members) {
    if (
      Object.prototype.hasOwnProperty.call(members, key) &&
      key !== client.userID
    ) {
      const member = members[key]
      images.push(member.user?.image)
    }
  }

  return images
}

export const getChannels = async (
  client,
  setChannelsInfo,
  onlyUnread = false
) => {
  const filter = {
    members: { $in: [client.userID || ''] },
    type: onlyUnread ? 'medical' : undefined,
  }

  const channels = await client.queryChannels(
    filter,
    [{ last_message_at: -1 }],
    {
      watch: true, // this is the default
      state: true,
    }
  )

  const channelsData: IChannelInfo[] = []

  channels.forEach((channel) => {
    if (onlyUnread && channel.state.unreadCount == 0) {
      return
    }

    const membersImages = getMemberImages(client, channel.state.members)

    const lastMessage = channel.lastMessage()

    if (!!lastMessage) {
      channelsData.push({
        lastMessage: lastMessage.text,
        user: lastMessage.user,
        membersImages,
        unreadCount: channel.countUnread(),
        type: channel.type,
      })
    }
  })
  setChannelsInfo(channelsData)
}

export function getNameFromMessage(message) {
  return message.user.name || getNameFromId(message.user.id) || capitalizeFirstLetter(message.user.role) || 'User'
}

export function getNameFromId(id) {
  const [name, lastName] = id.split('-')
  return `${capitalizeFirstLetter(name)} ${capitalizeFirstLetter(lastName)}`.trim()
}

export function capitalizeFirstLetter(str) {
  if (!str || str.length === 0) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


