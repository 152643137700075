import * as React from 'react'
import { useState } from 'react'

import { foldObj, UseState } from '../helpers/utils'

export interface FoodRestrictions {
  [groupName: string]: {
    [itemName: string]: boolean
  }
}

interface Props {
  foodRestrictions: FoodRestrictions
  onNext: ({ restrictions: FoodRestrictions }) => void
  stepNumber: number
}

function SelectFoodsStep(props: Props) {
  const [restrictions, setRestrictions]: UseState<FoodRestrictions> = useState(
    props.foodRestrictions
  )

  const onItemClick = ([group, item]) => {
    restrictions[group][item] = !restrictions[group][item]
    setRestrictions({ ...restrictions })
  }

  const onNext = () => {
    props.onNext({ restrictions })
  }

  return (
    <div className="msrd-card w-full">
      <div>
        <h4 className="text-secondary-900 font-semibold text-3xl">
          {`${props.stepNumber}.`} Select Foods
        </h4>
        <p className="font-secondary font-medium text-code-900 opacity-60 text-base tracking-wider my-4">
          Choose the food items you are comfortable consuming. Any unselected
          options will be excluded from your personalized meal plan.
        </p>
        <div className="diet">
          <Restrictions
            onItemClick={onItemClick}
            restrictionsNestedArray={restrictionsObjToNestedArray(restrictions)}
          />
        </div>
        <div className="form-group flex justify-between">
          <button
            onClick={onNext}
            name="button"
            type="submit"
            className={`msrd-button msrd-button-secondary msrd-button-lg block w-full max-w-sm mx-auto my-2`}
          >
            Next &rarr;
          </button>
        </div>
      </div>
    </div>
  )
}

function Restrictions(props) {
  return props.restrictionsNestedArray.map((group) => (
    <div key={group.name} className="diet-group" role="list">
      <div className="diet-heading">
        <span className="text-secondary-900 font-semibold text-lg capitalize">
          {group.name} items
        </span>
      </div>
      <div className="justify-center flex flex-wrap gap-2 max-w-xs mb-8 mx-auto">
        {group.items.map((item) => (
          <div
            role="checkbox"
            aria-checked={item.checked}
            key={item.name}
            className={`${
              !item.checked && 'opacity-30'
            } capitalize cursor-pointer bg-primary-100 py-1.5 px-3 inline-block rounded-[40px] hover:bg-primary-200`}
            onClick={() => props.onItemClick([group.name, item.name])}
            id={item.name}
          >
            <div className="text-sm leading-6 font-sans font-semibold text-brown">
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  ))
}

export default SelectFoodsStep

type FoodRestrictionsItem = {
  name: string
  checked: boolean
}
type FoodRestrictionsGroup = {
  name: string
  items: FoodRestrictionsItem[]
}
type FoodRestrictionsNestedArray = FoodRestrictionsGroup[]

function areFoodRestrictionGroups(
  restrict: (FoodRestrictionsItem | FoodRestrictionsGroup)[]
): restrict is FoodRestrictionsGroup[] {
  return restrict.length > 0 && isFoodRestrictionsGroup(restrict[0])
}

function isFoodRestrictionsGroup(
  restrict: FoodRestrictionsItem | FoodRestrictionsGroup
): restrict is FoodRestrictionsItem {
  return (restrict as FoodRestrictionsGroup).items !== undefined
}

export function restrictionsObjToNestedArray(
  obj: FoodRestrictions
): FoodRestrictionsNestedArray {
  const alg = (obj) => {
    const { key, value } = obj
    return Array.isArray(value)
      ? { name: key, items: value }
      : { name: key, checked: value }
  }

  const nestedArray = foldObj(obj, alg)

  return areFoodRestrictionGroups(nestedArray) ? nestedArray : []
}
