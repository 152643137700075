import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import InfoPanel from '../../components/MealPlan/InfoPanel'

export default class extends Controller {
  static targets = ['info']
  static values = {
    user: {
      height: String,
      targetWeight: Number,
    },
    userTraits: {
      activity_level: String,
      calories: Number,
      carbs: Number,
      current_weight: Number,
      fats: Number,
      health_goal: String,
      protein: Number,
      water: Number,
    },
  }

  renderComponent() {
    ReactDOM.render(
      <InfoPanel user={this.userValue} userTraits={this.userTraitsValue} />,
      this.infoTarget
    )
  }

  connect() {
    this.renderComponent()
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.infoTarget)
  }
}
