import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react'

type Chat = {
  customerUserId?: string
  setCustomerUserId?: Dispatch<SetStateAction<string>>
}

const ChatContext = React.createContext<Chat>({
  customerUserId: '',
  setCustomerUserId: () => {
    void 0
  },
})

export const ChatProvider = ({ userId, children }) => {
  const [customerUserId, setCustomerUserId] = useState<string>(userId)
  return (
    <ChatContext.Provider
      value={{
        customerUserId,
        setCustomerUserId,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChat = () => React.useContext(ChatContext)
