import './jquery'
import './src/confirm'
import './src/modals'
import './src/panels'
import './src/custom'
import './src/print'
import './src/meal_plans'
import './webapp/index'
import 'flowbite'
import 'flowbite/dist/flowbite.turbo.js'

import { Application } from '@hotwired/stimulus'
import { Alert } from 'tailwindcss-stimulus-components'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application
application.register('alert', Alert)
