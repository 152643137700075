import { useChat } from 'components/Chat/contexts/chatContext'
import React from 'react'
import { useMessageContext } from 'stream-chat-react'

import { RegularMessageBody } from './RegularMessage/Body'
import { RegularMessageHeader } from './RegularMessage/Header'

export default function AttachmentMessage() {
  const { message } = useMessageContext()
  const { customerUserId } = useChat()

  return (
    <>
      <RegularMessageHeader customerUserId={customerUserId} />
      <RegularMessageBody
        customerUserId={customerUserId}
        attachments={message.attachments}
      />
    </>
  )
}
