import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import Persona from 'persona'

export default class extends Controller {
  static values = {
    templateId: String,
    referenceId: String,
    environmentId: String,
    callbackUrl: String,
  }

  static targets = ['button', 'controls']

  connect() {
    const { templateIdValue, referenceIdValue, environmentIdValue } = this

    this.client = new Persona.Client({
      templateId: templateIdValue,
      referenceId: referenceIdValue,
      environmentId: environmentIdValue,
      onComplete: ({ inquiryId, status, fields }) => {
        console.log(`Sending finished inquiry ${inquiryId} to backend`)
        this.handlePersonaCompletion(inquiryId, status, fields)
        this.controlsTarget.classList.remove('action-pending')
        this.controlsTarget.classList.add('action-complete')
      },
      onCancel: ({ inquiryId, sessionToken }) => {
        this.buttonTarget.disabled = false
      },
      onError: (error) => console.log(error),
    })
  }

  show(event) {
    this.client.open()
    // disable button
    this.buttonTarget.disabled = true
    if (event) event.preventDefault()
  }

  hide() {
    this.client.cancel(true)
    console.log('Close')
  }

  async handlePersonaCompletion(inquiryId, status, fields) {
    const url = new URL(this.callbackUrlValue)
    await post(url.toString(), {
      responseKind: 'turbo-stream',
      body: {
        inquiryId: inquiryId,
        status: status,
        fields: fields,
      },
    })
  }
}
