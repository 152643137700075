import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import ProgressPage from '../../components/Progress/ProgressPage'

export default class extends Controller {
  static targets = ['progress']
  static values = {
    measurement: {
      target: Number,
      measurements: Array,
      preferredUnits: String,
    },
    isLegacyUser: Boolean,
    track: String,
  }

  renderComponent() {
    ReactDOM.render(
      <ProgressPage
        measurementData={this.measurementValue}
        isLegacyUser={this.isLegacyUserValue}
        track={this.trackValue}
      />,
      this.progressTarget
    )
  }

  connect() {
    this.renderComponent()
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.progressTarget)
  }
}
