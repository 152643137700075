import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['pharmacyData', 'form']
  static values = {
    userId: Number,
  }
  submitWith(e) {
    if (!!e.currentTarget.dataset['pharmacy']) {
      const phamacyObject = JSON.parse(e.currentTarget.dataset['pharmacy'])
      const pharmacyData = {
        name: phamacyObject['name'],
        address: phamacyObject['vicinity'],
        latitude: phamacyObject['geometry']['location']['lat'],
        longitude: phamacyObject['geometry']['location']['lng'],
        user_id: this.userIdValue,
        place_id: phamacyObject['place_id'],
      }
      this.pharmacyDataTarget.value = JSON.stringify(pharmacyData)
      this.formTarget.submit()
    }
  }
}
