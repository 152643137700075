import React, { MouseEvent } from 'react'

import P from './Primatives'

type ButtonSizes = 'small' | 'large'

type ButtonTypes = 'primary' | 'secondary'

const buttonCssSelectors = {
  primary: 'msrd-button-primary',
  secondary: 'msrd-button-secondary',
}

const sizeCssSelectors = {
  small: 'msrd-button-sm',
  large: 'msrd-button-lg',
}

interface Props {
  ariaLabel?: string
  children: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: (event: MouseEvent) => void
  size?: ButtonSizes
  type?: ButtonTypes
}

export default function MSRDButton(props: Props) {
  const { type, size, disabled, className, ...restProps } = props
  const buttonCss = buttonCssSelectors[type] || buttonCssSelectors.primary
  const sizeCss = sizeCssSelectors[size] || sizeCssSelectors.large

  return (
    <P.button
      onClick={props.onClick}
      className={`msrd-button ${buttonCss} ${sizeCss}
      ${className || ''}`}
      aria-disabled={disabled ? 'true' : 'false'}
      disabled={disabled}
      {...restProps}
    >
      {props.children}
    </P.button>
  )
}
