import {
  ActivityLevel,
  HealthGoal,
  Maybe,
  useUpdateHealthProfileMutation,
} from 'graphql/types'
import * as React from 'react'
import { useEffect, useState } from 'react'

interface Props {
  onNext: () => void
  currentWeight?: number
  weightUnit: string
  stepNumber: number
}

export default function UpdateMeasurementsStep(props: Props) {
  const [currentWeight, setCurrentWeight] = useState<Maybe<number>>(null)
  const [activityLevel, setActivityLevel] = useState<Maybe<ActivityLevel>>(null)
  const [validationError, setValidationError] = useState<Maybe<string>>(null)
  const [updateHealthProfile, { loading, error, data }] =
    useUpdateHealthProfileMutation()

  const onCurrentWeightChange = (e) => {
    setCurrentWeight(parseInt(e.target.value) || null)
  }

  const onActivityLevelChange = (e) => {
    setActivityLevel(e.target.value || null)
  }

  useEffect(() => {
    data && currentWeight && activityLevel && props.onNext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!!props.currentWeight) {
      setCurrentWeight(props.currentWeight);
    }
  }, [props.currentWeight])

  const onNext = () => {
    if (currentWeight && activityLevel) {
      setValidationError(null)
      updateHealthProfile({
        variables: {
          currentWeight,
          activityLevel,
          healthGoal: HealthGoal.LoseWeight,
        },
      })
    } else {
      setValidationError('Please fill out all fields')
    }
  }
  return (
    <>
      {error ? (
        <div className="bg-red-400 rounded p-8 text-lg">
          Something went wrong. Please try again.
        </div>
      ) : null}
      <div className="msrd-card w-full">
        <h3 className="font-semibold">
          {`${props.stepNumber}.`} Update Measurements
        </h3>
        <p className="font-secondary font-medium text-code-900 opacity-60 text-base tracking-wider my-4">
          To create a new meal guide, we need to first ensure your health
          profile is up to date. Please enter your measurements below.
        </p>
        <div className="md:flex md:gap-6 justify-around">
          <div className="form-group">
            <label
              className="text-sm font-semibold text-brown leading-6 tracking-semi-normal text-left"
              htmlFor="meal_plan_health_profile_attributes_current_weight"
            >
              Current weight
            </label>
            <div className="weight imperial flex items-center w-36">
              <input
                value={currentWeight ? currentWeight : ''}
                onChange={onCurrentWeightChange}
                className="required form-control border-1"
                type="number"
                name="meal_plan[health_profile_attributes][current_weight]"
                id="meal_plan_health_profile_attributes_current_weight"
              />
              <span className="text-secondary-900 font-serif font-semibold text-md ml-2">{props.weightUnit}</span>
            </div>
          </div>
          <div className="form-group">
            <label
              className="text-sm font-semibold text-brown leading-6 tracking-semi-normal text-left"
              htmlFor="meal_plan_activity_level"
            >
              Activity level
            </label>
            <select
              value={activityLevel ? activityLevel : ''}
              onChange={onActivityLevelChange}
              className="select form-control"
              name="meal_plan[activity_level]"
              id="meal_plan_activity_level"
            >
              <option value="">Select one</option>
              <option value={ActivityLevel.Low}>Low - low activity</option>
              <option value={ActivityLevel.Medium}>
                Medium - exercise 1-3 times per week
              </option>
              <option value={ActivityLevel.High}>
                High - excercise 4-5 times per week
              </option>
              <option value={ActivityLevel.VeryHigh}>
                Very High - exercise 6+ times per week
              </option>
              <option value={ActivityLevel.Athlete}>
                Athlete - rigorous exercise 1-2 times per day
              </option>
            </select>
          </div>
        </div>
        <div className="form-error text-red-600 text-sm font-semibold mb-2">
          {validationError}
        </div>
        <button
          onClick={onNext}
          name="button"
          className={`msrd-button msrd-button-secondary msrd-button-lg block mx-auto w-full max-w-sm ${
            loading ? 'opacity-50' : ''
          }`}
          disabled={loading}
        >
          Next &rarr;
        </button>
      </div>
    </>
  )
}
