import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dialog', 'observer']
  static values = { closable: Boolean }

  connect() {
    this.startObservingTurboFrame()
    if (this.isClosable()) {
      document.addEventListener('keydown', this.handleKeydown.bind(this))
    }
  }

  disconnect() {
    if (this.isClosable()) {
      document.removeEventListener('keydown', this.handleKeydown.bind(this))
    }
  }

  isClosable() {
    return this.hasClosableValue && this.closableValue
  }

  handleKeydown(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.hide()
    }
  }

  show() {
    this.dialogTarget.classList.remove('hidden')
  }

  hide() {
    this.dialogTarget.classList.add('hidden')
  }

  startObservingTurboFrame() {
    if (this.observerTarget == null) return
    this.observerListener = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.hide()
        }
      }
    })

    this.observerListener.observe(this.observerTarget, {
      childList: true,
      subtree: true,
    })
  }
}
