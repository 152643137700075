import { useEffect, useState } from 'react'
import { StreamChat } from 'stream-chat'

import { IScreenProp } from '../../types'

export const useStream = ({ streamData }: Partial<IScreenProp>) => {
  const [client, setClient] = useState<StreamChat>(null)
  const chatData = streamData.userData

  useEffect(() => {
    const newClient = new StreamChat(streamData.apiKey)

    const handleConnectionChange = ({ online = false }) => {
      if (!online) {
        return
      }
      setClient(newClient)
    }

    newClient.on('connection.changed', handleConnectionChange)

    newClient
      .connectUser(
        {
          id: chatData.user_id,
        },
        chatData.access_token
      )
      .catch((reason) => reason)

    return () => {
      newClient.off('connection.changed', handleConnectionChange)
      newClient.disconnectUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disconnect = () => {
    if (client.userID) {
      return client.disconnectUser()
    }
    return Promise.reject()
  }

  return {
    client,
    disconnect,
  }
}
