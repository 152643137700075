import React from 'react'
import { useState } from 'react'

import P from './Primatives'

export default function CollapsibleTile(props) {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  return (
    <P.div className={`tile collapsible-tile ${isOpen && 'open'}`}>
      <P.div
        className="collapsible-tile-heading"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {props.icon && <img src={props.icon} className="tile-icon" />}
        {props.title}
      </P.div>
      <P.div className="collapsible-tile-content">{props.children}</P.div>
    </P.div>
  )
}
