import Cookies from 'js-cookie'
import * as React from 'react'
import Tour, { ReactourStep } from 'reactour'

export const COOKIE_WALKTHROUGH = '_measured_walkthrough'

const steps: ReactourStep[] = [
  {
    content: 'Welcome to your Measured members portal!',
  },
  {
    content:
      'You can use this menu button to view your conversations (click here to expand menu)',
    selector: '.button-left-header',
  },
  {
    content: "Here, you'll be able to message your Coach or your Medical Team",
    observe: '.str-chat-channel-list',
    position: 'right',
    selector: '.str-chat__channel-list-messenger__main',
  },
  {
    content: 'Use these buttons to navigate through different parts of the app',
    selector: 'nav.nav-menu.bottom-0',
  },
  {
    content: 'And access your profile here anytime',
    selector: '.profile-drop-down-container',
  },
  {
    content:
      "And that's it! Now please watch this welcome message from our Medical Director, Dr. Adrian Blackwell",
    selector: '.welcome-video-message',
    position: [20, 190],
  },
]

export const Walkthrough = ({
  openMobileNav,
  closeMobileNav,
  showTour,
  setGoToStep,
  markVideoHighlight,
  goToStep = undefined,
}) => {
  const [isTourOpen, setTourOpen] = React.useState<boolean>(showTour)
  const [currentStep, setCurrentStep] = React.useState<undefined | number>()
  const finishedAlready = Cookies.get(COOKIE_WALKTHROUGH) === 'true'

  React.useEffect(() => {
    if (isTourOpen && !finishedAlready) {
      markVideoHighlight(false)
      if (currentStep === 2) {
        openMobileNav()
      } else {
        closeMobileNav()
        if (currentStep === 5) {
          markVideoHighlight()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, finishedAlready, isTourOpen])

  const closeHandler = () => {
    Cookies.set(COOKIE_WALKTHROUGH, 'true')
    setTourOpen(false)
  }

  return (
    !finishedAlready && (
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeHandler}
        getCurrentStep={(current_step) => {
          setGoToStep(undefined)
          setCurrentStep(current_step)
        }}
        scrollDuration={300}
        goToStep={goToStep}
      />
    )
  )
}
