import { StreamMessage } from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'

export const getDateFormatted = (dateString: string): string => {
  const date = new Date(dateString)
  const options1: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  }
  const datePart = new Intl.DateTimeFormat('en-US', options1).format(date)

  const options2: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }
  const timePart = new Intl.DateTimeFormat('en-US', options2).format(date)

  return `${datePart} • ${timePart}`
}

export const differenceInHours = (
  d1: string | Date,
  d2: string | Date
): number => {
  const date1 = new Date(d1)
  const date2 = new Date(d2)
  const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime())
  return differenceInMilliseconds / (1000 * 60 * 60)
}

export const isAcceptTreatment = (
  message: StreamMessage<DefaultStreamChatGenerics>
) => message.custom_type === 'accept_treament_plan'

export const isSysVideoMessage = (message) => {
  const { source, preview } =
    (!!message.attachment &&
      (message.attachment as {
        preview?: string
        source?: string
        type: string
      })) ||
    {}
  return message.custom_type === 'system' && (source || preview)
}

export const isSeparator = (message) => message.custom_type === 'separator'

export const isSystem = (message) => message.custom_type === 'system'


