import { DateGroupType, GroupDate } from '../Progress/types'

export function foldObj<T>(
  obj: object,
  f: (a: { key: string; value }) => T = (x) => x as unknown as T
): T[] {
  return Object.keys(obj).map((key) => {
    return f({
      key,
      value: isObject(obj[key]) ? foldObj(obj[key], f) : obj[key],
    })
  })
}

function isObject(objValue) {
  return (
    objValue && typeof objValue === 'object' && objValue.constructor === Object
  )
}

export type UseState<T> = [T, (x: T) => void]

export function unfold<T, U>(f: (b: T) => null | [U, T], initial: T) {
  let seed = initial
  let agg = []

  while (true) {
    const result = f(seed)
    if (result) {
      agg = agg.concat(result[0])
      seed = result[1]
    } else {
      return agg
    }
  }
}

export function iterate(totalIteration, f) {
  const b = 0
  const wrapperF = (iteration: number): null | [any, number] => {
    return iteration < totalIteration ? [f(iteration), (iteration += 1)] : null
  }
  return unfold(wrapperF, b)
}

export const getCurrentGroupDate = (
  group: DateGroupType,
  date: Date
): GroupDate => {
  const currentDate = structuredClone(date)
  const currentYear = currentDate.getFullYear()

  switch (group) {
    case 'month':
      const currentMonth = currentDate.getMonth()
      return {
        start: new Date(currentYear, currentMonth, 1),
        end: new Date(currentYear, currentMonth + 1, 0),
      }
    case 'year':
      return {
        start: new Date(currentYear, 0, 1),
        end: new Date(currentYear, 11, 31),
      }
    default:
      const firstDay = currentDate.getDate() - currentDate.getDay() // First day is the day of the month - the day of the week
      const lastDay = firstDay + 6 // last day is the first day + 6
      return {
        start: new Date(currentDate.setDate(firstDay)),
        end: new Date(currentDate.setDate(lastDay)),
      }
  }
}
