import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    eventName: String,
    properties: Object,
  }

  connect() {
    console.log('Event Tracker Controller connected')
  }

  track() {
    console.log('TRACKING EVENT', this.eventNameValue, this.propertiesValue)
    fetch('/analytics/track', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        event_name: this.eventNameValue,
        properties: this.propertiesValue,
      }),
    })
  }
}
