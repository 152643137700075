import {
  isSeparator,
  isSystem,
  isSysVideoMessage,
} from 'components/Chat/Components/Message/helpers'
import React from 'react'
import { useMessageContext } from 'stream-chat-react'
import AttachmentMessage from './AttachmentMessage'
import RegularMessage from './RegularMessage'
import SysMessage from './SysMessage'
import SysVideoMessage from './SysVideoMessage'

export default function Message() {
  const { message } = useMessageContext()

  if (isSysVideoMessage(message)) {
    return <SysVideoMessage />
  }

  if (isSystem(message)) {
    return <SysMessage />
  }

  if (isSeparator(message)) {
    // return <SeparatorMessage />
    return <></>
  }

  if (message.attachments?.length) {
    return <AttachmentMessage />
  }

  return <RegularMessage />
}
