import { Controller } from '@hotwired/stimulus'
import { createPopup } from '@typeform/embed'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    formId: String,
    userId: String,
    intakeFormId: String,
    redirectionUrl: String,
    hidden: String,
    submitUrl: String,
  }

  static targets = ['button']

  connect() {
    const hiddenData = !!this.hiddenValue ? JSON.parse(this.hiddenValue) : {}
    const { toggle } = createPopup(this.formIdValue, {
      autoFocus: true,
      onSubmit: () => {
        this.handleSubmit()
      },
      hidden: {
        user_id: this.userIdValue,
        intake_form_id: this.intakeFormIdValue,
        ...hiddenData,
      },
    })

    this.buttonTarget.addEventListener('click', (e) => {
      e.stopPropagation()
      toggle()
      _cio &&
        _cio.track('Intake Form Started', {
          formId: this.formIdValue,
          intakeFormId: this.intakeFormIdValue,
        })
    })
  }

  async handleSubmit() {
    _cio && _cio.track('Intake Form Submitted', {
      formId: this.formIdValue,
    })
    try {
      const response = await post(this.submitUrlValue, {
        responseKind: 'turbo-stream'
      })
      if (!response.ok) {
        console.error('Failed to submit intake form')
      }
    } catch (error) {
      console.error('Error submitting intake form:', error)
    }
    window.location = this.redirectionUrlValue
  }
}
