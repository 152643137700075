import { Diet, useFetchMacrosQuery } from 'graphql/types'
import * as React from 'react'

import { MealPlanParams } from './index'

interface Props {
  onNext: (params: MealPlanParams) => void
  stepNumber: number
}

export default function AdjustMacrosStep(props: Props) {
  const { loading, error, data, refetch } = useFetchMacrosQuery({
    variables: { diet: Diet.Keto },
  })

  const onNext = () => {
    data && props.onNext({ dietType: data.macros.diet })
  }

  return (
    <>
      {error ? (
        <div className="bg-red-400 rounded p-8 text-lg">
          Something went wrong. Please try again.
        </div>
      ) : null}
      <div className="msrd-card w-full">
        <div>
          <h3 className="font-semibold">
            {`${props.stepNumber}.`} Adjust Macros
          </h3>
          <p className="font-secondary font-medium text-code-900 opacity-60 text-base tracking-wider my-4">
            We&#39;ve calculated your macros based on the goals you set in the
            previous step. You can switch to a less restrictive diet by
            selecting 'Low Carb' in the toggle below.
          </p>
          <div className="text-sm center mx-auto bg-slate-100 flex flex-col gap-2.5 max-w-md p-4 rounded-lg">
            <DietTypes
              selectedDiet={data?.macros.diet}
              disabled={loading}
              onDietChange={(v) => refetch({ diet: v })}
            />
            <h4 className="text-secondary-900 font-semibold text-lg">
              Total Calories
            </h4>
            <MacroText
              loading={loading}
              error={error}
              data={data?.macros.calories}
            />
            <h4 className="text-secondary-900 font-semibold text-lg">Carbs</h4>
            <MacroText
              loading={loading}
              error={error}
              data={data?.macros?.carbs}
            />
            <h4 className="text-secondary-900 font-semibold text-lg">
              Protein
            </h4>
            <MacroText
              loading={loading}
              error={error}
              data={data?.macros.protein}
            />
            <h4 className="text-secondary-900 font-semibold text-lg">Fats</h4>
            <MacroText
              loading={loading}
              error={error}
              data={data?.macros.fats}
            />
          </div>
          <div className="form-group flex justify-between">
            <button
              onClick={onNext}
              name="button"
              type="submit"
              className={`msrd-button msrd-button-secondary msrd-button-lg block mx-auto w-full max-w-sm mt-4 ${
                loading || !!error ? 'opacity-50' : ''
              }`}
              disabled={loading || !!error}
            >
              Next &rarr;
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
function MacroText(props) {
  return (
    <p className="text-lg mb-4">
      {props.loading
        ? 'loading...'
        : props.error
        ? '-'
        : typeof props.data === 'number'
        ? `${props.data}`
        : `${Math.round(props.data.gm)} gm (${props.data.ratio}%)`}
    </p>
  )
}

const diets = [
  { name: 'Keto', value: Diet.Keto },
  { name: 'Low Carb', value: Diet.LowCarb },
]

function DietTypes(props) {
  const buttons = diets.map((diet, i) => {
    const baseClasses =
      'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500'
    let indexClasses = ''
    if (i === 0) {
      indexClasses = 'rounded-l-md'
    } else if (i === diets.length - 1) {
      indexClasses = 'rounded-r-md'
    }
    const selectClasses =
      diet.value === props.selectedDiet ? 'bg-white' : 'bg-gray-300'

    return (
      <button
        role="option"
        aria-selected={diet.value === props.selectedDiet}
        key={i}
        type="button"
        className={`${baseClasses} ${indexClasses} ${selectClasses}`}
        onClick={() => props.onDietChange(diet.value)}
      >
        {diet.name}
      </button>
    )
  })
  return (
    <div className="mb-4">
      <h4 className="text-secondary-900 font-semibold text-lg">Diet Type</h4>
      <label htmlFor="diets" className="sr-only">
        Select a Diet
      </label>
      <span
        className="relative z-0 inline-flex shadow-sm rounded-md"
        role="listbox"
      >
        {buttons}
      </span>
    </div>
  )
}
