import React from 'react'
import { useMessageContext } from 'stream-chat-react'

export default function SysMessage() {
  const { message } = useMessageContext()
  return (
    <div className="flex items-center justify-center mt-10">
      <div className="sys-message border border-pink-800/20 rounded bg-pink-300/30 p-4 font-serif text-sm text-pink-950/80 text-center">
        <div
          dangerouslySetInnerHTML={{ __html: message.html || message.text }}
        />
      </div>
    </div>
  )
}
