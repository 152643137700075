import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import ChatPage from '../../components/Chat/ChatPage'

export default class extends Controller {
  static targets = ['chat']
  static values = {
    streamData: {
      apiKey: String,
      userData: {
        app_id: String,
        user_id: String,
        access_token: String,
        channel_id: String,
        medical_channel_id: String | undefined,
      },
    },
    isLegacyUser: Boolean,
    track: String,
    isChannelListOpen: Boolean,
    channelType: String,
  }

  openChannelList() {
    this.isChannelListOpenValue = true
    this.renderComponent()
  }
  closeChannelList() {
    this.isChannelListOpenValue = false
    this.renderComponent()
  }

  renderComponent() {
    ReactDOM.render(
      <div className="w-full relative overflow-auto">
        <ChatPage
          streamData={this.streamDataValue}
          isLegacyUser={this.isLegacyUserValue}
          track={this.trackValue}
          isChannelListOpen={this.isChannelListOpenValue}
          channelType={this.channelTypeValue}
        />
      </div>,
      this.chatTarget
    )
  }

  connect() {
    this.renderComponent()
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.chatTarget)
  }
}
