import { Avatar as StreamChatAvatar } from 'stream-chat-react/dist/components/Avatar/Avatar'
import React from 'react'

export const Avatar = ({ image, user }) => {
  if (!!image) {
    return <StreamChatAvatar image={image} user={user} size={30} />
  }

  return (
    <span className="inline-flex h-[30px] w-[30px] items-center justify-center rounded-full bg-gray-500 mr-2 min-w-[30px]">
      <span className="font-medium leading-none text-white">
        {initialsFromName(user)}
      </span>
    </span>
  )
}

const initialsFromName = (user) => {
  if (user.dashboard_user) {
    return 'M'
  }
  const [firstName, lastName] = user.name?.split(' ') || user.id.split('-') || ['User']
  return `${firstName[0].toUpperCase()}${lastName ? lastName[0].toUpperCase() : ''}`
}
