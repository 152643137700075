import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import { NewMessagesCard } from '../../components/Chat/Components/NewMessagesCard'

export default class extends Controller {
  static targets = ['messages']
  static values = {
    streamData: {
      apiKey: String,
      userData: {
        app_id: String,
        user_id: String,
        access_token: String,
        channel_id: String,
        medical_channel_id: String | undefined,
      },
    },
    track: String,
  }

  renderComponent() {
    ReactDOM.render(
      <NewMessagesCard
        streamData={this.streamDataValue}
        track={this.trackValue}
      />,
      this.messagesTarget
    )
  }

  connect() {
    this.renderComponent()
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.messagesTarget)
  }
}
