import * as React from 'react'

interface Props {
  onSubmit: ({ start: string }) => void
  disableSubmit: boolean
  submissionError: boolean
  stepNumber: number
}

export default function SelectStartDateStep(props: Props) {
  const [startDate, setStartDate] = React.useState(
    new Date().toISOString().substr(0, 10)
  )

  const onSubmit = () => {
    props.onSubmit({ start: startDate })
  }
  const onStartDateChange = (e) => {
    setStartDate(e.target.value)
  }

  return (
    <>
      {props.submissionError ? (
        <div className="bg-red-400 rounded p-8 text-lg">
          Something went wrong. Please try again.
        </div>
      ) : null}
      <div className="msrd-card">
        <h4 className="text-secondary-900 font-semibold text-3xl">
          {`${props.stepNumber}.`} When do you want to start?
        </h4>
        <p className="font-secondary font-medium text-code-900 opacity-60 text-base tracking-wider my-4">
          Select a start date for your meal guide.
        </p>
        <div className="form-group">
          <label htmlFor="start_date">Start Date</label>
          <div className="weight imperial">
            <input
              value={startDate}
              onChange={onStartDateChange}
              className="required form-control mr-1"
              type="date"
              id="start_date"
            />
          </div>
        </div>
        <button
          onClick={onSubmit}
          name="button"
          type="submit"
          className={`msrd-button msrd-button-secondary msrd-button-lg block w-full mx-auto max-w-sm my-2 ${
            props.disableSubmit ? 'opacity-50' : ''
          }`}
          disabled={props.disableSubmit}
        >
          Create Meal Guide
        </button>
      </div>
    </>
  )
}
