import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'fileInput',
    'messageFeedback',
    'preview',
    'previewCloseIcon',
    'previewIcon',
    'previewLabel',
    'uploadIcon',
    'uploadWrapper',
  ]

  static values = {
    autoSubmit: Boolean,
    defaultDocumentIcon: String,
    defaultMultipleDocumentsIcon: String,
    defaultPreview: String,
    formId: String,
    hasValue: Boolean,
    inputId: String,
    inputLabel: String,
  }

  connect() {
    this.initDefaultValue()
    this.form.onsubmit = () => {
      this.displayFeedback()
      this.setUploadControls()
    }
  }

  setUploadControls() {
    const controls = document.querySelectorAll('.action-controls')
    if (controls.length !== 1) {
      return
    }
    console.log(this.fileInputTarget.files.length)
    if (this.fileInputTarget.files.length > 0) {
      controls[0].classList.add('action-complete')
      controls[0].classList.remove('action-pending')
    } else {
      controls[0].classList.add('action-pending')
      controls[0].classList.remove('action-complete')
    }
  }

  areInputFilesFilled() {
    const fileInputs = Array.from(
      this.form.querySelectorAll('input[type="file"]') || []
    )
    return fileInputs.every((fileInput) => fileInput.files.length > 0)
  }

  showPreview() {
    const preview_label = this.previewLabelTarget
    this.resetPreview(this.inputLabelValue)

    const [file] = this.fileInputTarget.files || []

    if (file) {
      let imgUrl = ''
      if (file.type.startsWith('image/')) {
        imgUrl = URL.createObjectURL(file)
      } else {
        imgUrl =
          this.fileInputTarget.files.length > 1
            ? this.defaultMultipleDocumentsIconValue
            : this.defaultDocumentIconValue
      }
      this.updatePreview(imgUrl)

      preview_label.innerText = file.name

      if (this.autoSubmitValue && this.areInputFilesFilled()) {
        const event = new Event('submit', {
          bubbles: true,
          cancelable: true,
        })
        this.form.dispatchEvent(event)
      }
    }
  }

  deleteFile(event) {
    // TODO: Finish this
    // get the data-input-id field from the event target
    const inputId = event.target.getAttribute('data-input-id')
  }

  resetPreview(label) {
    const preview_icon_container = this.previewIconTarget
    const preview_container = this.previewTarget
    const preview_label = this.previewLabelTarget
    const upload_icon = this.uploadIconTarget
    const preview_close_icon = this.previewCloseIconTarget

    preview_icon_container.classList.remove('hidden')
    upload_icon.classList.remove('hidden')
    preview_container.classList.add('hidden')
    preview_close_icon.classList.add('hidden')
    preview_label.innerText = label
  }

  reset(label) {
    this.resetPreview(label)
    this.fileInputTarget.value = ''
  }

  displayFeedback() {
    const feedbackElements = (this.form || document).querySelectorAll(
      '.message-feedback'
    )
    const feedbackElement = feedbackElements[feedbackElements.length - 1]
    feedbackElement.classList.remove('hidden')
  }

  initDefaultValue() {
    if (this.hasValueValue) {
      console.log({
        defaultPreviewValue: this.defaultPreviewValue,
        previewIconTarget: this.previewIconTarget,
        previewTarget: this.previewTarget,
      })

      this.updatePreview(this.defaultPreviewValue)
    }
    this.form = document.getElementById(this.formIdValue)
  }

  updatePreview(urlImg) {
    const preview_icon_container = this.previewIconTarget
    const preview_container = this.previewTarget
    preview_icon_container.classList.add('hidden')
    preview_container.classList.remove('hidden')
    preview_container.style.backgroundImage = `url(${urlImg})`
    preview_container.style.backgroundSize = 'cover'
    preview_container.style.backgroundPosition = 'center'
    preview_container.style.backgroundRepeat = 'no-repeat'
  }
}
