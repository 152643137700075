import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import NewMealPlanForm from '../../components/NewMealPlanForm'

export default class extends Controller {
  static targets = ['form']
  static values = {
    currentWeight: Number,
    weightUnit: String,
    foodRestrictions: {
      common: {
        avocado: Boolean,
        eggs: Boolean,
        gluten: Boolean,
        nuts: Boolean,
        milk: Boolean,
        cheese: Boolean,
      },
      meat: {
        beef: Boolean,
        chicken: Boolean,
        lamb: Boolean,
        bacon: Boolean,
        fish: Boolean,
        shellfish: Boolean,
      },
      vegetable: {
        mushrooms: Boolean,
        asparagus: Boolean,
        broccoli: Boolean,
        beets: Boolean,
        cauliflower: Boolean,
        tomato: Boolean,
      },
      fruit: {
        apple: Boolean,
        banana: Boolean,
        grapes: Boolean,
        strawberry: Boolean,
        raspberry: Boolean,
        blueberries: Boolean,
      },
    },
  }

  renderComponent() {
    ReactDOM.render(
      <NewMealPlanForm
        currentWeight={this.currentWeightValue}
        weightUnit={this.weightUnitValue}
        foodRestrictions={this.foodRestrictionsValue}
      />,
      this.formTarget
    )
  }

  connect() {
    this.renderComponent()
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.formTarget)
  }
}
