import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    email: String,
    name: String,
    phoneNumber: String,
    bookingSuccessfulUrl: String,
    updateUrl: String,
    calendarLink: String,
  }

  connect() {
    const bookingSuccessfulUrl = this.bookingSuccessfulUrlValue
    const updateuUrl = this.updateUrlValue

    ;(function (C, A, L) {
      let p = function (a, ar) {
        a.q.push(ar)
      }
      let d = C.document
      C.Cal =
        C.Cal ||
        function () {
          let cal = C.Cal
          let ar = arguments
          if (!cal.loaded) {
            cal.ns = {}
            cal.q = cal.q || []
            d.head.appendChild(d.createElement('script')).src = A
            cal.loaded = true
          }
          if (ar[0] === L) {
            const api = function () {
              p(api, arguments)
            }
            const namespace = ar[1]
            api.q = api.q || []
            typeof namespace === 'string'
              ? (cal.ns[namespace] = api) && p(api, ar)
              : p(cal, ar)
            return
          }
          p(cal, ar)
        }
    })(window, 'https://cal.msrd.co/embed/embed.js', 'init')

    Cal('init', { origin: 'https://cal.msrd.co' })
    Cal('ui', {
      config: {
        name: this.nameValue,
        email: this.emailValue,
      },
      styles: { branding: { brandColor: '#f5c700' } },
    })

    Cal('on', {
      action: 'bookingSuccessful',
      callback: async function (data) {
        const url = new URL(updateuUrl)
        await post(url.toString(), {
          responseKind: 'turbo-stream',
        })
        window.location = bookingSuccessfulUrl
      },
    })
  }
}
