import { Dialog, Transition } from '@headlessui/react'
import { useUpdateHealthProgressMutation } from 'graphql/types'
import React, { Fragment, useRef, useState } from 'react'

import { IEditWeightProps } from './types'
import { getWeight, unitConversion } from './utils'

export const EditWeightButton = ({
  preferredUnits,
  id,
  currentData,
}: IEditWeightProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [updateHealthProgress, { loading }] = useUpdateHealthProgressMutation()

  const cancelButtonRef = useRef(null)
  const weightEl = useRef(null)
  const notesEl = useRef(null)
  const defaultWeight = getWeight(currentData.weight, preferredUnits)

  const onSubmit = (e) => {
    e.preventDefault()
    const currentWeight = weightEl.current.value
    console.log({ currentWeight })
    const currentNotes = notesEl.current.value
    const minValue =
      preferredUnits === 'lbs' ? 50 : Math.floor(50 / unitConversion)
    const maxValue =
      preferredUnits === 'lbs' ? 800 : Math.floor(800 / unitConversion)
    if (currentWeight < minValue) {
      alert(`Weight cannot be less than ${minValue} ${preferredUnits}`)
      return
    }
    if (currentWeight > maxValue) {
      alert(`Weight cannot be more than ${maxValue} ${preferredUnits}`)
      return
    }

    updateHealthProgress({
      variables: {
        id,
        notes: currentNotes,
        weight: parseFloat(currentWeight),
      },
    })
      .then(() => {
        weightEl.current.value = ''
        notesEl.current.value = ''
        setOpen(false)
        window.location.reload()
      })
      .catch((error) => {
        console.log({ error })
        setOpen(false)
      })
  }

  return (
    <>
      <button
        type="button"
        className="msrd-button msrd-button-secondary msrd-button-sm"
        onClick={() => setOpen(true)}
      >
        Edit
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white p-4 md:p-6">
                    <Dialog.Title
                      as="h2"
                      className="text-2xl text-left font-medium leading-6 text-gray-900 mb-2"
                    >
                      Edit Weight
                    </Dialog.Title>
                    <form onSubmit={onSubmit}>
                      <div className="relative">
                        <label
                          htmlFor="hp-weight"
                          className="mb-0 font-semibold capitalize text-lg"
                        >
                          Weight:
                        </label>
                        <div className="flex items-center gap-2">
                          <input
                            id="hp-weight"
                            ref={weightEl}
                            type="number"
                            placeholder="-"
                            className="p-2 my-2 border shadow-sm"
                            required
                            disabled={loading}
                            defaultValue={defaultWeight}
                          />
                          <span>{preferredUnits}</span>
                        </div>
                      </div>
                      <div className="relative">
                        <label
                          htmlFor="hp-notes"
                          className="mb-0 capitalize text-lg"
                        >
                          Notes:
                        </label>
                        <textarea
                          id="hp-notes"
                          ref={notesEl}
                          rows={2}
                          placeholder="eg. special date, changes to diet, etc."
                          className="p-2 my-2 border shadow-sm w-full"
                          disabled={loading}
                          defaultValue={currentData?.notes}
                        />
                      </div>
                      <div className="flex gap-2 justify-end">
                        <button
                          type="button"
                          className="msrd-button msrd-button-secondary"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                          disabled={loading}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="justify-center msrd-button msrd-button-primary"
                          disabled={loading}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
