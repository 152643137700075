import * as React from 'react'

export const MessageIcon = () => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
  >
    <path d="M 4 4 C 3.07 4 2.2923125 4.6429063 2.0703125 5.5039062 L 12 11.726562 L 21.935547 5.5214844 C 21.719547 4.6504844 20.937 4 20 4 L 4 4 z M 2 7.734375 L 2 18 C 2 19.103 2.897 20 4 20 L 20 20 C 21.103 20 22 19.103 22 18 L 22 7.7558594 L 12 14 L 2 7.734375 z" />
  </svg>
)
