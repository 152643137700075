import * as React from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { Chat } from 'stream-chat-react'

import { useStream } from '../../../hooks/useRestMutation/useStream'
import { IScreenProp } from '../../../types'
import { getChannels } from '../helpers'
import { IChannelInfo } from '../types'
import { UnreadIndicator } from '../UnreadIndicator'
import { ChannelMenu } from './ChannelMenu'
import { MessageIcon } from './MessageIcon'

export const MessageButton = ({
  streamData,
  isLegacyUser,
}: Partial<IScreenProp>) => {
  const { client } = useStream({ streamData: streamData })

  const [unreadMessages, setUnreadMessages] = React.useState<number>()
  const [channelsInfo, setChannelsInfo] = React.useState<IChannelInfo[]>([])

  const menuRef = React.useRef<HTMLDivElement>(null)

  const closeDropdown = () => {
    if (!menuRef?.current?.classList.contains('hidden')) {
      menuRef?.current?.classList.add('hidden')
    }
  }

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: closeDropdown,
  })

  React.useEffect(() => {
    if (!!client && !isLegacyUser) {
      client.on((event) => {
        if (
          event.total_unread_count !== null &&
          (event.type === 'message.new' ||
            event.type === 'notification.message_new')
        ) {
          setUnreadMessages(event.total_unread_count || 0)
        } else if (event.type === 'health.check' && !!event.me) {
          setUnreadMessages(event.me.total_unread_count || 0)
        }
        if (event.type === 'notification.mark_read') {
          setUnreadMessages(event.total_unread_count || 0)
        }
      })

      getChannels(client, setChannelsInfo)
    }

    setUnreadMessages((client?.user?.total_unread_count as number) || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, isLegacyUser])

  React.useEffect(() => {
    if (!!client) {
      getChannels(client, setChannelsInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessages])

  return (
    !!client &&
    channelsInfo.length > 0 && (
      <Chat client={client}>
        <div className="relative" ref={detectClickOutsideRef}>
          <div
            onClick={() => menuRef?.current?.classList.toggle('hidden')}
            className="relative items-center cursor-pointer"
          >
            <UnreadIndicator unReadNotification={unreadMessages} />
            <MessageIcon />
          </div>
          <ChannelMenu menuRef={menuRef} channelsInfo={channelsInfo} />
        </div>
      </Chat>
    )
  )
}
