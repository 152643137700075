import * as React from 'react'
import { Chat } from 'stream-chat-react'

import { useStream } from '../../../hooks/useRestMutation/useStream'
import { IScreenProp } from '../../../types'
import { getChannels } from '../helpers'
import { NewMessages } from './NewMessages'

export interface IChannelInfo {
  lastMessage?: string
  membersImages?: string[]
  unreadCount?: number
  type: string
}

export const NewMessagesCard = ({ streamData }: Partial<IScreenProp>) => {
  const { client } = useStream({ streamData: streamData })

  const [unreadMessages, setUnreadMessages] = React.useState<number>()
  const [channelsInfo, setChannelsInfo] = React.useState<IChannelInfo[]>([])

  const menuRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!!client) {
      client.on((event) => {
        if (
          event.total_unread_count !== null &&
          (event.type === 'message.new' ||
            event.type === 'notification.message_new')
        ) {
          setUnreadMessages(event.total_unread_count || 0)
        } else if (event.type === 'health.check' && !!event.me) {
          setUnreadMessages(event.me.total_unread_count || 0)
        }
        if (event.type === 'notification.mark_read') {
          setUnreadMessages(event.total_unread_count || 0)
        }
      })

      getChannels(client, setChannelsInfo, true)
    }

    setUnreadMessages((client?.user?.total_unread_count as number) || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  React.useEffect(() => {
    if (!!client) {
      getChannels(client, setChannelsInfo, true)
      if (!!unreadMessages && typeof window !== 'undefined') {
        const newMessagesCardElement =
          document.querySelector('.new-messages-card')
        if (unreadMessages === 0) {
          if (!newMessagesCardElement?.classList.contains('hidden')) {
            newMessagesCardElement?.classList.add('hidden')
          }
        } else {
          newMessagesCardElement?.classList.remove('hidden')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessages])

  return (
    !!client && (
      <Chat client={client}>
        <div className="relative">
          <NewMessages menuRef={menuRef} channelsInfo={channelsInfo} />
        </div>
      </Chat>
    )
  )
}
