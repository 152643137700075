import React from 'react'
import { Attachment, useMessageContext } from 'stream-chat-react'

import { getDateFormatted } from '../helpers'

export function RegularMessageBody({ customerUserId, attachments }) {
  const { message } = useMessageContext()
  const isCustomer = message.user.id === customerUserId
  let messageClasses

  if (isCustomer) {
    messageClasses = 'bg-blue-200 max-w-[80%] min-w-[25%] rounded-l-lg rounded-t-lg'
  } else {
    messageClasses =
      'bg-white ml-3 md:ml-[30px] rounded-r-lg rounded-b-lg shadow'
  }

  return (
    <div
      className={`flex flex-col ${
        isCustomer ? 'items-end justify-end' : 'justify-stretch'
      }`}
    >
      <div
        className={[
          'font-sans font-normal text-base p-3 md:p-4 str-message-body',
          messageClasses,
        ].join(' ')}
      >
        <div dangerouslySetInnerHTML={{ __html: message.html }}></div>
        {attachments && <Attachment attachments={attachments} />}
        {/* show message date if customer */}
      </div>
      {isCustomer && (
        <div className="flex items-center justify-end my-1">
          <div className="font-sans font-normal text-xs text-gray-800/60">
            {getDateFormatted(message.created_at as string)}
          </div>
        </div>
      )}
    </div>
  )
}
