import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import LibraryPage from '../../components/Library/LibraryPage'

export default class extends Controller {
  static targets = ['explore']
  static values = {
    lesson: {
      imageTagHtml: String,
      name: String,
      contentTagHtml: String,
      previous: String | undefined,
      next: String | undefined,
    },
  }

  renderComponent() {
    ReactDOM.render(<LibraryPage />, this.exploreTarget)
  }

  connect() {
    this.renderComponent()
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.exploreTarget)
  }
}
