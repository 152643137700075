import * as React from 'react'

import { IChannelInfo } from '../types'
import { UnreadIndicator } from '../UnreadIndicator'

export const ChannelMenuItem = ({
  lastMessage,
  membersImages,
  unreadCount,
  type,
}: IChannelInfo) => (
  <>
    <div
      className="flex px-3 py-2 justify-between active:bg-gray-100 hover:bg-gray-100 cursor-pointer"
      onClick={() => (window.location.href = `/chat/${type}`)}
    >
      <div className="text-ellipsis overflow-hidden">
        <p className="text-base font-medium">
          {type === 'messaging' ? 'Health Coach' : 'Care Team'}
        </p>
        <p className="text-xs text-gray-400 truncate">{lastMessage}</p>
        <UnreadIndicator unReadNotification={unreadCount} />
      </div>
      <div className="flex">
        {membersImages
          ?.filter((memberImage) => !!memberImage)
          .map((memberImage, index) => (
            <div
              key={`memberImg${index}`}
              className={`rounded-full h-[31px] overflow-hidden ${
                index > 0 ? '-ml-5' : ''
              }`}
            >
              <img className="max-w-fit h-full" src={memberImage} />
            </div>
          ))}
      </div>
    </div>
    <div className="border-t border-gray-300 px-6 text-gray-800 bg-white font-bold text-xs uppercase"></div>
  </>
)
