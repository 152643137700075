import React from 'react'

export interface ButtonGroupOption {
  id: string
  label: string
  onClick: () => void
}
interface ButtonGroupProps {
  options: ButtonGroupOption[]
  selectedId: string
}

const defaultClasses =
  'relative inline-flex items-center px-4 py-1 text-lg font-medium focus:z-10 focus:outline-none font-semibold'

const getClasses = (index, selectedId) => {
  let classes = defaultClasses

  if (selectedId) {
    classes += ' bg-white text-gray-800 shadow-md'
  } else {
    classes += ' text-gray-500'
  }

  if (index === 0) {
    classes += ' rounded-l-md'
  } else if (index === 2) {
    classes += ' rounded-r-md'
  }

  return classes
}

export default function ButtonGroup({ options, selectedId }: ButtonGroupProps) {
  return (
    <span className="msrd-button-group relative z-0 inline-flex mx-auto items-center justify-center bg-gray-100 shadow-inner border border-gray-200 rounded-md">
      {options.map((item, i) => (
        <button
          type="button"
          key={i}
          className={getClasses(i, selectedId === item.id)}
          onClick={item.onClick}
        >
          {item.label}
        </button>
      ))}
    </span>
  )
}
