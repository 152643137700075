import { ApolloProvider } from '@apollo/client'
import * as React from 'react'
import { createCache, createClient } from 'util/apollo'

const client = createClient(createCache())

export default (Component) => {
  return (props: any) => (
    <ApolloProvider client={client}>
      <Component {...props} />
    </ApolloProvider>
  )
}
