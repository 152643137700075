import * as Sentry from '@sentry/react'
import * as React from 'react'
import { Chat } from 'stream-chat-react'

import { useStream } from '../../hooks/useRestMutation/useStream'
import { IScreenProp } from '../../types'
import Provider from '../../util/hoc/Provider'
import { ChatProvider } from './contexts/chatContext'
import { Stream } from './Stream'

const ChatPage = (props: Partial<IScreenProp>) => {
  const { streamData } = props
  const { client } = useStream({ streamData: streamData })

  return (
    !!client && (
      <ChatProvider userId={streamData.userData.user_id}>
        <div className="chat-page-container">
          <div className="webapp-chat-container">
            <Chat client={client}>
              <Stream {...props} />
            </Chat>
          </div>
        </div>
      </ChatProvider>
    )
  )
}

export default Provider(
  Sentry.withErrorBoundary(ChatPage, {
    fallback: <p>An error has occurred</p>,
  })
)
