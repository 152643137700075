import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import { MessageButton } from '../../components/Chat/Components/MessageButton'

export default class extends Controller {
  static values = {
    streamData: {
      apiKey: String,
      userData: {
        app_id: String,
        user_id: String,
        access_token: String,
        channel_id: String,
        medical_channel_id: String | undefined,
      },
    },
    isLegacyUser: Boolean,
  }

  connect() {
    ReactDOM.render(
      <MessageButton
        streamData={this.streamDataValue}
        isLegacyUser={this.isLegacyUserValue}
      />,
      this.element
    )
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}
