import React, { useEffect } from 'react'
import { useMessageContext } from 'stream-chat-react'

import { getDateFormatted } from '../helpers'
import { Avatar } from 'components/Chat/Components/Avatar'
import { capitalizeFirstLetter, getNameFromId, getNameFromMessage } from 'components/Chat/helpers'

export function RegularMessageHeader({ customerUserId }) {
  const { message } = useMessageContext()
  const [userRole, setUserRole] = React.useState(null)

  useEffect(() => {
    if (message.user.msrd_role) {
      setUserRole(message.user.msrd_role)
    }
  }, [customerUserId])

  if (message.user.id === customerUserId) {
    return null
  }

  const name = message.user.name || getNameFromId(message.user.id) || capitalizeFirstLetter(message.user.role) || 'User'

  return (
    !message?.hide_header && (
      <div className="flex justify-between pt-6 mt-4 mb-2">
        <div className="flex flex-grow items-center md:gap-0.5">
          <Avatar image={message.user.image} user={message.user} />
          <div className="flex-grow">
            <span className="font-sans font-medium text-sm md:text-base">
              {getNameFromMessage(message)}
            </span>
            <div className="flex items-center gap-1">
              <div className="font-sans font-normal text-xs md:text-sm text-gray-500">
                {getDateFormatted(message.created_at as string)}
              </div>
              {userRole && (
                <span className="hidden md:inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                  {userRole}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}
