import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryZoomContainer,
} from 'victory'

import ButtonGroup, { ButtonGroupOption } from '../ButtonGroup'
import { getCurrentGroupDate } from '../helpers/utils'
import { Tile } from '../Tile'
import { AddWeight } from './AddWeight'
import { CurrentRange } from './CurrentRange'
import {
  DateGroupType,
  DomainChart,
  GroupDate,
  IMeasurementProps,
  Measurement,
} from './types'
import {
  getWeight,
  primaryColor,
  primaryColorTransparent,
  SORT,
  sortMeasurements,
} from './utils'
import { AXIS, ChartData, MARGIN_VALUE, stroke } from './utils'

const getRangeValues = (
  currentData: ChartData[],
  groupDate: GroupDate
): [number, number] => {
  const values = currentData
    .filter((d) => d[AXIS.X] >= groupDate.start && d[AXIS.X] <= groupDate.end)
    .map((d) => d[AXIS.Y]) as Array<number>

  if (values.length > 0) {
    return [
      Math.min(...values) - MARGIN_VALUE,
      Math.max(...values) + MARGIN_VALUE,
    ]
  }
  return [100, 300]
}

const transformData = (
  preferredUnits: string,
  measurements: Measurement[]
): ChartData[] => {
  return sortMeasurements(measurements, SORT.ASC).map((measurement) => ({
    [AXIS.X]: new Date(measurement.created_at),
    [AXIS.Y]: getWeight(measurement.weight, preferredUnits),
  }))
}

export const Chart = ({ measurementData }: IMeasurementProps) => {
  const { preferredUnits, measurements, target } = measurementData
  const [targetData, setTargetDate] = useState<GroupDate>()

  const currentData = transformData(preferredUnits, measurements)

  const lastMeasurement = currentData[currentData.length - 1] || {}
  const mostRecentWeightAdded = (lastMeasurement[AXIS.X] as Date) || new Date()

  const weekDateRange = getCurrentGroupDate('week', mostRecentWeightAdded)
  const monthDateRange = getCurrentGroupDate('month', mostRecentWeightAdded)
  const yearDateRange = getCurrentGroupDate('year', mostRecentWeightAdded)

  const [zoomDomain, setZoomDomain] = useState<DomainChart>({
    x: [weekDateRange.start, weekDateRange.end],
    y: getRangeValues(currentData, weekDateRange),
  })
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateGroupType>('week')

  const buttonGroupOptions: ButtonGroupOption[] = [
    {
      id: 'week',
      label: 'Week',
      onClick: () => {
        setSelectedDateRange('week')
        setZoomDomain({
          x: [weekDateRange.start, weekDateRange.end],
          y: getRangeValues(currentData, weekDateRange),
        })
      },
    },
    {
      id: 'month',
      label: 'Month',
      onClick: () => {
        setSelectedDateRange('month')
        setZoomDomain({
          x: [monthDateRange.start, monthDateRange.end],
          y: getRangeValues(currentData, monthDateRange),
        })
      },
    },
    {
      id: 'year',
      label: 'Year',
      onClick: () => {
        setSelectedDateRange('year')
        setZoomDomain({
          x: [yearDateRange.start, yearDateRange.end],
          y: getRangeValues(currentData, yearDateRange),
        })
      },
    },
  ]

  const onZoomDomainChange = (domain: DomainChart) => {
    setTargetDate({
      start: domain.x[0] as Date,
      end: domain.x[1] as Date,
    })

    setZoomDomain({
      ...domain,
      y: [
        (domain.y[0] as number) - MARGIN_VALUE,
        (domain.y[1] as number) + MARGIN_VALUE,
      ],
    })
  }

  const getCurrentDateRange = useCallback(() => {
    switch (selectedDateRange) {
      case 'year':
        return yearDateRange
      case 'month':
        return monthDateRange
    }

    return weekDateRange
  }, [monthDateRange, selectedDateRange, weekDateRange, yearDateRange])

  const getMargingScatter = useCallback(() => {
    const day = 86400000
    switch (selectedDateRange) {
      case 'year':
        return day * 30
      case 'month':
        return day * 2
    }

    return day / 2
  }, [selectedDateRange])

  const formatShortDate = (stringDate) =>
    stringDate.slice(0, stringDate.lastIndexOf('/'))

  useEffect(() => {
    setTargetDate(getCurrentDateRange())
  }, [selectedDateRange])

  return (
    <Tile>
      <div className="max-w-2xl mx-auto w-full md:py-6">
        <div className="text-center">
          <ButtonGroup
            options={buttonGroupOptions}
            selectedId={selectedDateRange}
          />
        </div>
        <CurrentRange zoomDomain={zoomDomain} />
        <div>
          <VictoryChart
            width={600}
            height={360}
            scale={{ x: 'time' }}
            containerComponent={
              <VictoryZoomContainer
                zoomDimension="x"
                zoomDomain={zoomDomain}
                onZoomDomainChange={onZoomDomainChange}
                allowZoom={false}
              />
            }
          >
            <VictoryLine
              style={{
                data: { stroke, strokeWidth: 3 },
              }}
              data={currentData}
              x={AXIS.X}
              y={AXIS.Y}
            />
            {selectedDateRange !== 'year' && (
              <VictoryScatter
                style={{
                  data: {
                    fill: '#fff',
                    stroke,
                    strokeWidth: 3,
                  },
                }}
                size={selectedDateRange === 'week' ? 9 : 4}
                data={currentData}
                labels={({ datum }) => datum[AXIS.Y]}
                labelComponent={<VictoryLabel renderInPortal dy={-20} />}
                x={AXIS.X}
                y={AXIS.Y}
              />
            )}
            {/** Horizontal Axis. */}
            <VictoryAxis
              style={{
                axis: { stroke: 'transparent' },
                grid: {
                  stroke: '#ddd',
                  strokeWidth: 0.5,
                },
                tickLabels: { fontSize: 20, fill: '#333' },
              }}
              tickFormat={(t) =>
                selectedDateRange !== 'year'
                  ? formatShortDate(t.toLocaleDateString('en-US'))
                  : t.toLocaleString('en-US', {
                      month: 'short',
                    })
              }
            />

            {/** Vertical Axis. */}
            <VictoryAxis
              dependentAxis
              offsetX={600}
              style={{
                axis: { stroke: 'transparent' },
                grid: {
                  stroke: '#ddd',
                  strokeWidth: 0.5,
                },
                tickLabels: { fontSize: 20, fill: '#999' },
              }}
            />

            <VictoryScatter
              data={[
                {
                  x:
                    new Date(
                      (targetData || getCurrentDateRange()).end
                    ).getTime() - getMargingScatter(),
                  y: target,
                  symbol: 'diamond',
                  size: 10,
                  fill: 'red',
                },
              ]}
              style={{ data: { fill: 'tomato' } }}
              labels={['Target Weight']}
              labelComponent={
                <VictoryLabel
                  dy={30}
                  textAnchor="middle"
                  style={[{ fill: primaryColor, fontSize: 14 }]}
                />
              }
            />
            <VictoryArea
              style={{
                data: {
                  stroke: primaryColor,
                  strokeDashoffset: 1,
                  strokeWidth: 1,
                  strokeLinecap: 'round',
                  fill: primaryColorTransparent,
                },
              }}
              data={[
                {
                  x: 0,
                  y: target,
                  y0: 0,
                },
                {
                  x: (targetData || getCurrentDateRange()).end,
                  y: target,
                  y0: 0,
                },
              ]}
            />
          </VictoryChart>
        </div>
        <div className="max-w-md mx-auto w-full mt-4">
          <AddWeight preferredUnits={preferredUnits} />
        </div>
      </div>
    </Tile>
  )
}
