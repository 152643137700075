import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggle(event) {
    // toggle is-active class on this element
    this.element.classList.toggle('is-active')

    // toggle class on body too
    document.body.classList.toggle('show-nav')

    event.preventDefault()
  }
}
