import * as React from 'react'

import { ChannelMenuItem } from './ChannelMenuItem'

export const ChannelMenu = ({ menuRef, channelsInfo }) => (
  <div
    ref={menuRef}
    className="hidden origin-top-right w-56 absolute right-[-4rem] mt-2 rounded-md border border-gray-400 drop-shadow-md transition ease-out duration-100 z-50"
  >
    <div
      className="rounded-md bg-white"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="chat-menu"
    >
      {channelsInfo.map((channel, index) => (
        <ChannelMenuItem key={`channelInfo${index}`} {...channel} />
      ))}
    </div>
  </div>
)
