import * as React from 'react'

import { NewMessage } from './NewMessage'

export const NewMessages = ({ menuRef, channelsInfo }) => (
  <div ref={menuRef} className="w-full mt-2">
    {channelsInfo.map((channel, index) => (
      <NewMessage key={`newMessageInfo${index}`} {...channel} />
    ))}
  </div>
)
