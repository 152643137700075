import { EditWeightButton } from 'components/Progress/EditWeightButton'
import React, { useCallback } from 'react'

import { getHeaders } from '../../util/requests'
import P from '../Primatives'
import { Tile } from '../Tile'
import { IMeasurementProps } from './types'
import { getWeight, SORT, sortMeasurements } from './utils'

export const WeightHistory = ({ measurementData }: IMeasurementProps) => {
  const { measurements, preferredUnits } = measurementData

  const removeMeasurement = useCallback((id) => {
    if (typeof window !== 'undefined') {
      //@ts-expect-error window.showConfirmModal exists on this context
      window.showConfirmModal(
        'Are you sure?',
        'Clicking the button below will delete this progress update.',
        'Delete It',
        () => {
          fetch(`/old_progress/${id}`, {
            headers: getHeaders(),
            method: 'DELETE',
          })
            .then((res) => {
              return res.text()
            })
            .then(() => {
              //@ts-expect-error window.hideModal exists on this context
              window.hideModal()
              window.location.reload()
            })
        }
      )
    }
  }, [])
  return (
    <div>
      <h2 className="tile-heading">Weight History</h2>
      <Tile className="mb-15 divide-y">
        {sortMeasurements(measurements, SORT.DESC).map(
          ({ id, weight, created_at, notes, initial }, i) => (
            <div key={i} className={'flex flex-col py-3'}>
              <div
                key={i}
                className={'flex gap-1 justify-between items-center'}
              >
                <div className={'text-2xl'} style={{ minWidth: '130px' }}>
                  {getWeight(weight, preferredUnits)} {preferredUnits}
                  <div className="flex gap-1 text-xs font-normal uppercase text-gray-400">
                    <span>On</span>
                    <span>
                      {new Date(created_at).toLocaleString('en-US', {
                        weekday: 'short',
                        day: '2-digit',
                        month: '2-digit',
                        hour: 'numeric',
                        minute: '2-digit',
                      })}
                    </span>
                  </div>
                </div>
                <div className="justify-end flex gap-2">
                  <EditWeightButton
                    id={id}
                    preferredUnits={preferredUnits}
                    currentData={{ weight, notes }}
                  />
                  <button
                    type="button"
                    disabled={initial}
                    className="msrd-button msrd-button-danger msrd-button-sm"
                    onClick={() => removeMeasurement(id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
              {notes && (
                <div className="w-full block p-1 pl-2 border-l-4 border-gray-300 mt-1">
                  {notes}
                </div>
              )}
            </div>
          )
        )}
      </Tile>
    </div>
  )
}
